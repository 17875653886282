import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import globalReducer from '@containers/App/state/reducer';
import authPagesReducer from '@containers/AuthPages/state/reducer';
import tipsPageReducer from '@containers/TipsPage/state/reducer';
import surveyPageReducer from '@containers/SurveyPage/state/reducer';
import subscriptionPageReducer from '@containers/SubscriptionPage/state/reducer';
import downloadPageReducer from '@containers/DownloadPage/state/reducer';
import userPageReducer from '@containers/UserPage/state/reducer';
import reportRedirectPageReducer from '@containers/ReportRedirectPage/state/reducer';

export const store = configureStore({
  reducer: {
    global: globalReducer,
    authPages: authPagesReducer,
    tipsPage: tipsPageReducer,
    surveyPage: surveyPageReducer,
    subscriptionPage: subscriptionPageReducer,
    downloadPage: downloadPageReducer,
    userPage: userPageReducer,
    reportRedirectPage: reportRedirectPageReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
