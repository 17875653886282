import React, { memo, ReactNode, useRef } from 'react';
import styled from 'styled-components';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import {
  COLORS,
  FONTS,
  FONT_SIZE,
  NO_SELECT,
  PADDING,
} from '@constants/styles';
import { TextAlignTypes } from '@constants/commonTypes';
import InputWrapper from '../InputWrapper';

const ST = {
  Wrapper: styled.div`
    // padding: 0px ${PADDING.SM};
    width: 100%;
  `,
  Container: styled.div<{
    textAlign: string;
    fontSize: string;
    showBorder: boolean;
  }>`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    // border: ${p => (p.showBorder ? 1 : 0)}px solid ${COLORS.BORDER_GRAY};
    // border-radius: 4px;

    text-align: ${p => p.textAlign};
    span {
      font-family: ${FONTS.NORMAL};
      font-size: ${p => p.fontSize};
      ${NO_SELECT}
    }

    input {
      width: 100%;
      background-color: transparent;
      border: 0px;
      padding: ${PADDING.MD} ${PADDING.SM};
      color: ${COLORS.BLACK};
      text-align: ${p => p.textAlign};
      font-family: ${FONTS.NORMAL};
      font-size: ${p => p.fontSize};

      ::-webkit-outer-spin-button,
      ::-webkit-inner-spin-button {
        margin-left: 10px;
      }

      :focus {
        outline: none;
        border: 0px;
        // border: ${p => (p.showBorder ? 2 : 0)}px solid ${COLORS.MAIN};
      }

      :disabled {
        color: ${COLORS.GRAY};
        border-bottom: 0px;
        ${NO_SELECT}
      }
    }
  `,
  Suffix: styled.div<{ fontSize: string; disabled: boolean }>`
    font-family: ${FONTS.NORMAL};
    font-size: ${p => p.fontSize};
    padding-right: ${PADDING.SM};
    color: ${p => (p.disabled ? COLORS.GRAY : COLORS.BLACK)};
  `,
};

interface Props {
  value?: string | number;
  onChange: (a?: number) => void;
  fontSize?: string;
  disabled?: boolean;
  placeholder?: string;
  prefix?: string;
  suffix?: string;
  textAlign?: TextAlignTypes;
  decimalScale?: number;
  min?: number;
  hideBorder?: boolean;
  icon?: ReactNode;
}

const CustomNumberInput: React.FC<Props> = ({
  value,
  onChange,
  fontSize = FONT_SIZE.PX16,
  disabled = false,
  placeholder,
  prefix = '',
  suffix = '',
  textAlign = 'right',
  decimalScale = 2,
  min,
  hideBorder = false,
  icon,
}) => {
  const containerRef = useRef<HTMLInputElement>(null);
  const showBorder = !hideBorder;

  const updateInput = (ev: NumberFormatValues) => {
    if (ev.floatValue === undefined) {
      onChange(undefined);
      return;
    }
    const fixedValue = +ev.floatValue || 0;

    onChange(fixedValue);
  };

  const avoidNegativeFcn = (e: {
    code: string;
    preventDefault: () => void;
  }) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };

  const inputFocused = (e: any) => {
    if (containerRef.current) {
      e.preventDefault();
      e.target.setSelectionRange(prefix.length, e.target.value.length);
    }
  };

  return (
    <InputWrapper hideBorder={!showBorder} leadingIcon={icon}>
      <ST.Container
        ref={containerRef}
        textAlign={textAlign}
        fontSize={fontSize}
        showBorder={showBorder}
      >
        <NumericFormat
          value={value === undefined ? null : value}
          thousandSeparator
          prefix={prefix}
          onValueChange={updateInput}
          disabled={disabled}
          placeholder={placeholder}
          onWheel={(e: any) => (e.target as HTMLElement).blur()}
          displayType={'input'}
          decimalScale={decimalScale}
          onKeyDown={(min || 0) >= 0 ? avoidNegativeFcn : () => {}}
          //   fixedDecimalScale={decimalScale > 0}
          onFocus={inputFocused}
        />
        {suffix !== '' && (
          <ST.Suffix fontSize={fontSize} disabled={disabled}>
            {suffix}
          </ST.Suffix>
        )}
      </ST.Container>
    </InputWrapper>
  );
};

export default memo(CustomNumberInput);
