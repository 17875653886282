import { sum } from 'lodash-es';

import { FormOptionType } from '@components/CustomForm/types';
import { COLORS, FONTS, FONT_SIZE } from '@constants/styles';

import { getCurrentAge } from '@utils/fcns/getCurrentAge';
import { formatByType } from '@utils/formatting';

import { SURVEY_ERRORS } from './surveyErrors';
import { SurveyAnswersType, SurveyDefaultAnswersFcn } from './surveyAnswers';
import { RESULT_DESCRIPTION_PAGE } from './resultDescriptionPage';
import { RESULT_DESCRIPTION_PAGE_CUSTOM } from './resultDescriptionPageCustom';

export type SurveyPageType = {
  key: string;
  pageType: 'standard';
  title: string;
  questions: (
    a: SurveyAnswersType,
    u: (val: SurveyAnswersType) => void,
  ) => Array<FormOptionType>;
  validation: (a: SurveyAnswersType) => { [key: string]: string };
  disableNext?: boolean;
  customNext?: {
    label: string;
    fcn: () => void;
  };
  printKey?: string;
  widthOverride?: number;
};

type ValidationReturn = { [key: string]: string };

export const SURVEY_PAGES = (
  answers: SurveyAnswersType,
  isSubscribed: boolean,
  openSubscriptionPage: () => void,
): Array<SurveyPageType> => {
  const taxableAssetTotal = {
    p: sum([
      answers.p_current_rrsp_rrif,
      answers.p_current_dc_pension_lif,
      answers.p_current_tfsa,
    ]),
    s: sum([
      answers.s_current_rrsp_rrif,
      answers.s_current_dc_pension_lif,
      answers.s_current_tfsa,
    ]),
  };

  const ntsTotal = {
    p: answers.p_current_nts_assets || 0,
    s: answers.s_current_nts_assets || 0,
  };

  const SurveyDefaultAnswers = SurveyDefaultAnswersFcn({
    taxableAssetTotal,
    ntsTotal,
  });

  const pages: Array<SurveyPageType> = [
    // MARK: Page 1
    {
      key: 'terms_of_use_and_disclaimer',
      pageType: 'standard',
      title: `Terms of Use and Disclaimer`,
      questions: (
        tempAnswers: SurveyAnswersType,
        updateAnswers: (val: SurveyAnswersType) => void,
      ) => [
        {
          key: 'terms_disclaimer',
          type: 'rawText',
          value: [
            {
              text: `The use of the Personal Enhanced Retirement Calculator (“PERC”) and any output therefrom is at your own risk and on an “as is” basis without warranty of any kind, whether express or implied. PERC is not intended to be a substitute for professional, legal or financial advice. While PERC was created for the benefit of retirement savers, the creators shall not held be responsible for any claims or damages whatsoever relating to PERC, including without limitation claims relating to the content or accuracy of PERC, any output or calculations generated by PERC or indirect damages such as loss of income. Please click below to agree with these terms of use and disclaimer.`,
            },
          ],
        },
        {
          key: 'spacer',
          type: 'spacer',
        },
        {
          key: 'terms_of_use_and_disclaimer',
          type: 'checkbox',
          title: {
            label: ``,
          },
          stackType: 'STACKED',
          value: tempAnswers.terms_of_use_and_disclaimer,
          updateValue: (val: string) => {
            updateAnswers({ ...tempAnswers, terms_of_use_and_disclaimer: val });
          },
          options: {
            key: 'y',
            label: 'I agree to the above Terms of Use and Disclaimer',
          },
        },
      ],
      validation: (ans: SurveyAnswersType) => {
        const temp: ValidationReturn = {};

        if (ans.terms_of_use_and_disclaimer.length === 0) {
          temp.terms_of_use_and_disclaimer =
            SURVEY_ERRORS.terms_of_use_and_disclaimer;
        }

        return temp;
      },
    },

    // MARK: Page 2
    {
      key: 'who_is_this_estimate_for',
      pageType: 'standard',
      title: `Who is this estimate for?`,
      questions: (
        tempAnswers: SurveyAnswersType,
        updateAnswers: (val: SurveyAnswersType) => void,
      ) => [
        ...(isSubscribed
          ? ([
              {
                key: 'who_is_this_page_locked',
                type: 'rawText',
                value: [
                  {
                    text: `NOTE: The values from your active subscription have been automatically applied.`,
                    color: COLORS.DARK_RED,
                  },
                ],
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        {
          key: 'p_birthdate',
          type: 'datePicker',
          title: {
            label: `What is your birth date?`,

            color: isSubscribed ? COLORS.GRAY : '',

            helpText: {
              key: 'p_birthdate_help',
              text: `You must be between ages 50 and 80 to use PERC. `,
            },
          },
          stackType: 'STACKED',
          value: tempAnswers.p_birthdate,
          updateValue: (val: string | null) => {
            const tempAge = getCurrentAge(val);
            updateAnswers({
              ...tempAnswers,
              p_birthdate: val,
              p_current_age: tempAge,
              ...(tempAge < 60
                ? {
                    p_monthly_cpp_pension:
                      SurveyDefaultAnswers.p_monthly_cpp_pension,
                  }
                : {}),
              ...(tempAge < 65
                ? {
                    p_monthly_oas_pension:
                      SurveyDefaultAnswers.p_monthly_oas_pension,
                  }
                : {}),
            });
          },
          props: {
            disabled: isSubscribed,
            enableOpenPicker: !isSubscribed,
            hideIcon: true,
            minDate: 'January 1 1944 12:00:00 GMT-0400',
            maxDate: 'December 31 1974 12:00:00 GMT-0400',
          },
        },
        {
          key: 'p_gender',
          type: 'radio',
          title: {
            label: `What is your gender?`,

            color: isSubscribed ? COLORS.GRAY : '',

            helpText: {
              key: 'p_gender_help',
              text: `This is necessary for calculating an annuity. `,
            },
          },
          stackType: 'STACKED',
          value: tempAnswers.p_gender,
          updateValue: (val: string) => {
            updateAnswers({
              ...tempAnswers,
              p_gender: val,
            });
          },
          props: {
            options: [
              { key: 'Male', label: 'Male' },
              { key: 'Female', label: 'Female' },
            ],
            direction: 'row',

            disabled: isSubscribed,
          },
        },
        {
          key: 'p_years_in_canada',
          type: 'number',
          title: {
            label: `How many years will you have lived in Canada from ages 18 to 65?`,

            color: isSubscribed ? COLORS.GRAY : '',

            helpText: {
              key: 'p_years_in_canada_help',
              text: `Enter a number only if you haven't started your OAS pension yet.`,
            },
          },
          stackType: 'STACKED',
          value: tempAnswers.p_years_in_canada,
          updateValue: (val?: number) => {
            updateAnswers({ ...tempAnswers, p_years_in_canada: val });
          },
          props: {
            disabled: isSubscribed,
            suffix: `yrs`,
            min: 0,
            max: 47,
            decimalScale: 0,
          },
        },
        ...(!(answers.hide_spouse_question === true)
          ? ([
              {
                key: 'include_spouse',
                type: 'radio',
                title: {
                  label: `Are you entering data here for yourself only or are you including your spouse? (check one)`,

                  helpText: {
                    key: 'include_spouse_help',
                    text: `If you include your spouse, you will also need to enter data about his/her financial assets.`,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.include_spouse,
                updateValue: (val: string) => {
                  updateAnswers({
                    ...tempAnswers,
                    include_spouse: val,
                    ...(val === 'n'
                      ? {
                          s_monthly_cpp_pension:
                            SurveyDefaultAnswers.s_monthly_cpp_pension,
                          s_expected_future_cpp_pension_perc:
                            SurveyDefaultAnswers.s_expected_future_cpp_pension_perc,
                          s_cpp_start_age_toggle:
                            SurveyDefaultAnswers.s_cpp_start_age_toggle,
                          s_cpp_start_age_override:
                            SurveyDefaultAnswers.s_cpp_start_age_override,
                          s_monthly_oas_pension:
                            SurveyDefaultAnswers.s_monthly_oas_pension,
                          s_oas_start_age_toggle:
                            SurveyDefaultAnswers.s_oas_start_age_toggle,
                          s_oas_start_age_override:
                            SurveyDefaultAnswers.s_oas_start_age_override,
                          s_current_rrsp_rrif:
                            SurveyDefaultAnswers.s_current_rrsp_rrif,
                          s_current_dc_pension_lif:
                            SurveyDefaultAnswers.s_current_dc_pension_lif,
                          s_current_tfsa: SurveyDefaultAnswers.s_current_tfsa,
                          s_current_nts_assets:
                            SurveyDefaultAnswers.s_current_nts_assets,
                          s_monthly_db_pension:
                            SurveyDefaultAnswers.s_monthly_db_pension,
                          s_db_pension_start_age:
                            SurveyDefaultAnswers.s_db_pension_start_age,
                          s_future_db_pension_increases:
                            SurveyDefaultAnswers.s_future_db_pension_increases,
                          s_pre_retire_work_years:
                            SurveyDefaultAnswers.s_pre_retire_work_years,
                          s_pre_retire_self_employed:
                            SurveyDefaultAnswers.s_pre_retire_self_employed,
                          s_pre_retire_annual_pay:
                            SurveyDefaultAnswers.s_pre_retire_annual_pay,
                          s_pre_retire_full_time_incr:
                            SurveyDefaultAnswers.s_pre_retire_full_time_incr,
                          s_pre_retire_rrsp:
                            SurveyDefaultAnswers.s_pre_retire_rrsp,
                          s_pre_retire_use_max_tfsa:
                            SurveyDefaultAnswers.s_pre_retire_use_max_tfsa,
                          s_pre_retire_tfsa:
                            SurveyDefaultAnswers.s_pre_retire_tfsa,
                          s_pre_retire_you_dc:
                            SurveyDefaultAnswers.s_pre_retire_you_dc,
                          s_pre_retire_employer_dc:
                            SurveyDefaultAnswers.s_pre_retire_employer_dc,
                          s_pre_retire_db: SurveyDefaultAnswers.s_pre_retire_db,
                          s_expect_future_earnings:
                            SurveyDefaultAnswers.s_expect_future_earnings,
                          s_future_part_time_pay:
                            SurveyDefaultAnswers.s_future_part_time_pay,
                          s_yrs_part_time_pay:
                            SurveyDefaultAnswers.s_yrs_part_time_pay,
                          s_future_windfall_net_tax_expenses:
                            SurveyDefaultAnswers.s_future_windfall_net_tax_expenses,
                          s_years_until_windfall:
                            SurveyDefaultAnswers.s_years_until_windfall,
                          s_old_annuity_amount:
                            SurveyDefaultAnswers.s_old_annuity_amount,
                          s_purchased_annuity:
                            SurveyDefaultAnswers.s_purchased_annuity,
                        }
                      : {}),
                  });
                },
                props: {
                  options: [
                    { key: 'n', label: 'Myself only' },
                    {
                      key: 'y',
                      label: 'I am including my spouse (recommended)',
                    },
                  ],
                  direction: 'column',
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(tempAnswers.include_spouse === 'y'
          ? ([
              {
                key: 's_birthdate',
                type: 'datePicker',
                title: {
                  label: `What is your spouse's birth date?`,

                  color: isSubscribed ? COLORS.GRAY : '',

                  helpText: {
                    key: 's_birthdate_help',
                    text: `Your spouse must be between 50 and 80 to use PERC. `,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.s_birthdate,
                updateValue: (val: string | null) => {
                  const tempAge = getCurrentAge(val);
                  updateAnswers({
                    ...tempAnswers,
                    s_birthdate: val,

                    s_current_age: tempAge,
                    ...(tempAge < 60
                      ? {
                          s_monthly_cpp_pension:
                            SurveyDefaultAnswers.s_monthly_cpp_pension,
                        }
                      : {}),
                    ...(tempAge < 65
                      ? {
                          s_monthly_oas_pension:
                            SurveyDefaultAnswers.s_monthly_oas_pension,
                        }
                      : {}),
                  });
                },
                props: {
                  disabled: isSubscribed,
                  enableOpenPicker: !isSubscribed,
                  hideIcon: true,
                  minDate: 'January 1 1944 12:00:00 GMT-0400',
                  maxDate: 'December 31 1974 12:00:00 GMT-0400',
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(tempAnswers.include_spouse === 'y'
          ? ([
              {
                key: 's_gender',
                type: 'radio',
                title: {
                  label: `What is your spouse's gender?`,

                  color: isSubscribed ? COLORS.GRAY : '',
                },
                stackType: 'STACKED',
                value: tempAnswers.s_gender,
                updateValue: (val: string) => {
                  updateAnswers({
                    ...tempAnswers,
                    s_gender: val,
                  });
                },
                props: {
                  options: [
                    { key: 'Male', label: 'Male' },
                    { key: 'Female', label: 'Female' },
                  ],
                  direction: 'row',

                  disabled: isSubscribed,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(tempAnswers.include_spouse === 'y'
          ? ([
              {
                key: 's_years_in_canada',
                type: 'number',
                title: {
                  label: `How many years will your spouse have lived in Canada from ages 18 to 65?`,

                  color: isSubscribed ? COLORS.GRAY : '',

                  helpText: {
                    key: 's_years_in_canada_help',
                    text: `Enter a number only if your spouse has not started her OAS pension yet. `,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.s_years_in_canada,
                updateValue: (val?: number) => {
                  updateAnswers({ ...tempAnswers, s_years_in_canada: val });
                },
                props: {
                  disabled: isSubscribed,
                  suffix: `yrs`,
                  min: 0,
                  max: 47,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
      ],
      validation: (ans: SurveyAnswersType) => {
        const temp: ValidationReturn = {};

        if (!ans.p_birthdate) {
          temp.p_birthdate = 'Please choose a date.';
        }

        if (getCurrentAge(ans.p_birthdate) < 50) {
          temp.p_birthdate =
            'You should be older than 50 to get value out of PERC';
        }

        if (getCurrentAge(ans.p_birthdate) > 80) {
          temp.p_birthdate =
            'You should be younger than 80 to get value out of PERC';
        }

        if (!['Male', 'Female'].includes(ans.p_gender)) {
          temp.p_gender = SURVEY_ERRORS.p_gender;
        }

        if (!ans.p_years_in_canada && ans.p_years_in_canada !== 0) {
          temp.p_years_in_canada = 'Please enter a value.';
        }

        if (ans.p_years_in_canada !== undefined) {
          if (ans.p_years_in_canada < 0) {
            temp.p_years_in_canada = 'Please enter a value >= 0.';
          }
        }

        if (ans.p_years_in_canada !== undefined) {
          if (ans.p_years_in_canada > 47) {
            temp.p_years_in_canada = 'Please enter a value <= 47.';
          }
        }

        if (!(ans.hide_spouse_question === true)) {
          if (!['n', 'y'].includes(ans.include_spouse)) {
            temp.include_spouse = SURVEY_ERRORS.include_spouse;
          }
        }

        if (ans.include_spouse === 'y') {
          if (!ans.s_birthdate) {
            temp.s_birthdate = 'Please choose a date.';
          }

          if (getCurrentAge(ans.s_birthdate) < 50) {
            temp.s_birthdate =
              'You should be older than 50 to get value out of PERC';
          }

          if (getCurrentAge(ans.s_birthdate) > 80) {
            temp.s_birthdate =
              'You should be younger than 80 to get value out of PERC';
          }
        }

        if (ans.include_spouse === 'y') {
          if (!['Male', 'Female'].includes(ans.s_gender)) {
            temp.s_gender = SURVEY_ERRORS.s_gender;
          }
        }

        if (ans.include_spouse === 'y') {
          if (!ans.s_years_in_canada && ans.s_years_in_canada !== 0) {
            temp.s_years_in_canada = 'Please enter a value.';
          }

          if (ans.s_years_in_canada !== undefined) {
            if (ans.s_years_in_canada < 0) {
              temp.s_years_in_canada = 'Please enter a value >= 0.';
            }
          }

          if (ans.s_years_in_canada !== undefined) {
            if (ans.s_years_in_canada > 47) {
              temp.s_years_in_canada = 'Please enter a value <= 47.';
            }
          }
        }
        return temp;
      },
    },

    // MARK: Page 3
    {
      key: 'your_financial_assets',
      pageType: 'standard',
      title: `Your Financial Assets`,
      questions: (
        tempAnswers: SurveyAnswersType,
        updateAnswers: (val: SurveyAnswersType) => void,
      ) => [
        ...((answers.p_current_age || 0) >= 60
          ? ([
              {
                key: 'p_monthly_cpp_pension',
                type: 'number',
                title: {
                  label: `How much C/QPP pension are you currently receiving each month?`,

                  helpText: {
                    key: 'p_monthly_cpp_pension_help',
                    text: `C/QPP means Canada/Quebec Pension Plans. Payments must start between ages 60 and 70. Leave blank if you haven't started your C/QPP pension yet.`,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.p_monthly_cpp_pension,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    p_monthly_cpp_pension: val,
                    ...((val || 0) > 0
                      ? {
                          p_expected_future_cpp_pension_perc:
                            SurveyDefaultAnswers.p_expected_future_cpp_pension_perc,
                        }
                      : {}),
                  });
                },
                props: {
                  suffix: `CAD`,
                  min: 0,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...((tempAnswers.p_monthly_cpp_pension || 0) === 0
          ? ([
              {
                key: 'p_expected_future_cpp_pension_perc',
                type: 'number',
                title: {
                  label: `What is your expected future C/QPP pension (as a percentage of the maximum)?`,

                  helpText: {
                    key: 'p_expected_future_cpp_pension_perc_help',
                    text: `This is typically about 80%. If you always worked and earned more than the Canadian average, it could be close to 100%. To get a better estimate, you could open a My Service Canada Account or call Service Canada. The % you enter should not take into account the adjustment for starting CPP before or after age 65.`,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.p_expected_future_cpp_pension_perc,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    p_expected_future_cpp_pension_perc: val,
                  });
                },
                props: {
                  suffix: `%`,
                  min: 0,
                  max: 100,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed && tempAnswers.p_monthly_cpp_pension === 0
          ? ([
              {
                key: 'p_cpp_start_age_toggle',
                type: 'radio',
                title: {
                  label: `Do you want PERC to choose your optimal CPP Starting Age?`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 'p_cpp_start_age_toggle',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],

                  helpText: {
                    key: 'p_cpp_start_age_toggle_help',
                    text: `If you answer 'no' then PERC will calculate the optimal starting age for you.`,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.p_cpp_start_age_toggle,
                updateValue: (val: string) => {
                  updateAnswers({
                    ...tempAnswers,
                    p_cpp_start_age_toggle: val,
                    ...(val === 'y'
                      ? {
                          p_cpp_start_age_override:
                            SurveyDefaultAnswers.p_cpp_start_age_override,
                        }
                      : {}),
                  });
                },
                props: {
                  options: [
                    { key: 'y', label: 'Yes' },
                    { key: 'n', label: 'No' },
                  ],
                  direction: 'row',
                  disabled: !isSubscribed,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed &&
        tempAnswers.p_cpp_start_age_toggle === 'n' &&
        tempAnswers.p_monthly_cpp_pension === 0
          ? ([
              {
                key: 'p_cpp_start_age_override',
                type: 'number',
                title: {
                  label: `What age would you like your CPP to start?`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 'p_cpp_start_age_override',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],
                },
                stackType: 'STACKED',
                value: tempAnswers.p_cpp_start_age_override,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    p_cpp_start_age_override: val,
                  });
                },
                props: {
                  disabled: !isSubscribed,

                  min: 60,
                  max: 70,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...((answers.p_current_age || 0) >= 65
          ? ([
              {
                key: 'p_monthly_oas_pension',
                type: 'number',
                title: {
                  label: `How much monthly OAS pension are you currently receiving?`,

                  helpText: {
                    key: 'p_monthly_oas_pension_help',
                    text: `OAS means Old Age Security pension. Payments start between ages 65 and 70. Leave this blank if you are not yet receiving OAS.`,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.p_monthly_oas_pension,
                updateValue: (val?: number) => {
                  updateAnswers({ ...tempAnswers, p_monthly_oas_pension: val });
                },
                props: {
                  suffix: `CAD`,
                  min: 0,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed && tempAnswers.p_monthly_oas_pension === 0
          ? ([
              {
                key: 'p_oas_start_age_toggle',
                type: 'radio',
                title: {
                  label: `Do you want PERC to choose your optimal OAS Starting Age?`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 'p_oas_start_age_toggle',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],

                  helpText: {
                    key: 'p_oas_start_age_toggle_help',
                    text: `If you answer 'no' then PERC will calculate the optimal starting age for you.`,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.p_oas_start_age_toggle,
                updateValue: (val: string) => {
                  updateAnswers({
                    ...tempAnswers,
                    p_oas_start_age_toggle: val,
                    ...(val === 'y'
                      ? {
                          p_oas_start_age_override:
                            SurveyDefaultAnswers.p_oas_start_age_override,
                        }
                      : {}),
                  });
                },
                props: {
                  options: [
                    { key: 'y', label: 'Yes' },
                    { key: 'n', label: 'No' },
                  ],
                  direction: 'row',
                  disabled: !isSubscribed,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed &&
        tempAnswers.p_oas_start_age_toggle === 'n' &&
        tempAnswers.p_monthly_oas_pension === 0
          ? ([
              {
                key: 'p_oas_start_age_override',
                type: 'number',
                title: {
                  label: `What age would you like your OAS to start?`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 'p_oas_start_age_override',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],
                },
                stackType: 'STACKED',
                value: tempAnswers.p_oas_start_age_override,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    p_oas_start_age_override: val,
                  });
                },
                props: {
                  disabled: !isSubscribed,

                  min: 65,
                  max: 70,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        {
          key: 'p_current_rrsp_rrif',
          type: 'number',
          title: {
            label: `How much do you have in RRSPs and RRIFs combined?`,

            helpText: {
              key: 'p_current_rrsp_rrif_help',
              text: `RRSP means Registered Retirement Savings Plan. RRIF is a Registered Retirement Income Fund. Enter the most recent known balance. `,
            },
          },
          stackType: 'STACKED',
          value: tempAnswers.p_current_rrsp_rrif,
          updateValue: (val?: number) => {
            updateAnswers({ ...tempAnswers, p_current_rrsp_rrif: val });
          },
          props: {
            suffix: `CAD`,
            min: 0,
            decimalScale: 0,
          },
        },
        {
          key: 'p_current_dc_pension_lif',
          type: 'number',
          title: {
            label: `How much do you have in your DC pension plans and LIFs combined?`,

            helpText: {
              key: 'p_current_dc_pension_lif_help',
              text: `DC pension plan means a defined contribution pension plan. A LIF is a Life Income Fund (it is like a RRIF).`,
            },
          },
          stackType: 'STACKED',
          value: tempAnswers.p_current_dc_pension_lif,
          updateValue: (val?: number) => {
            updateAnswers({ ...tempAnswers, p_current_dc_pension_lif: val });
          },
          props: {
            suffix: `CAD`,
            min: 0,
            decimalScale: 0,
          },
        },
        {
          key: 'p_current_tfsa',
          type: 'number',
          title: {
            label: `How much do you currently have in your TFSA?`,

            helpText: {
              key: 'p_current_tfsa_help',
              text: `A TFSA is a Tax-Free Savings Account. `,
            },
          },
          stackType: 'STACKED',
          value: tempAnswers.p_current_tfsa,
          updateValue: (val?: number) => {
            updateAnswers({ ...tempAnswers, p_current_tfsa: val });
          },
          props: {
            suffix: `CAD`,
            min: 0,
            decimalScale: 0,
          },
        },
        {
          key: 'p_current_nts_assets',
          type: 'number',
          title: {
            label: `How much do you currently have in financial assets that are not tax-sheltered?`,

            helpText: {
              key: 'p_current_nts_assets_help',
              text: `For example, GICs outside of your RRSP, a regular savings account, a brokerage account or an employee stock purchase plan.`,
            },
          },
          stackType: 'STACKED',
          value: tempAnswers.p_current_nts_assets,
          updateValue: (val?: number) => {
            updateAnswers({ ...tempAnswers, p_current_nts_assets: val });
          },
          props: {
            suffix: `CAD`,
            min: 0,
            decimalScale: 0,
          },
        },
        {
          key: 'p_monthly_db_pension',
          type: 'number',
          title: {
            label: `How much monthly income do you expect to receive from a workplace DB pension plan (not CPP)?`,

            helpText: {
              key: 'p_monthly_db_pension_help',
              text: `DB means a defined benefit pension plan (it does not mean C/QPP). Enter the estimated monthly amount. Set to 0 if you have no DB pension.`,
            },
          },
          stackType: 'STACKED',
          value: tempAnswers.p_monthly_db_pension,
          updateValue: (val?: number) => {
            updateAnswers({
              ...tempAnswers,
              p_monthly_db_pension: val,
              ...((val || 0) === 0
                ? {
                    p_db_pension_start_age:
                      SurveyDefaultAnswers.p_db_pension_start_age,
                    p_future_db_pension_increases:
                      SurveyDefaultAnswers.p_future_db_pension_increases,
                  }
                : {}),
            });
          },
          props: {
            suffix: `CAD`,
            min: 0,
            decimalScale: 0,
          },
        },
        ...((tempAnswers.p_monthly_db_pension || 0) > 6000
          ? ([
              {
                key: 'p_monthly_db_pension_warning',
                type: 'rawText',
                value: [
                  {
                    text: `WARNING: The DB pension value that you have entered is abnormally high, which could lead to invalid results. Please ensure that you are entering a monthly value.`,
                    color: COLORS.DARK_RED,
                  },
                ],
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...((tempAnswers.p_monthly_db_pension || 0) > 0
          ? ([
              {
                key: 'p_db_pension_start_age',
                type: 'number',
                title: {
                  label: `What age will your DB pension start?`,

                  helpText: {
                    key: 'p_db_pension_start_age_help',
                    text: `Typical starting age is 65. Enter current age if DB pension has already started.`,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.p_db_pension_start_age,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    p_db_pension_start_age: val,
                  });
                },
                props: {
                  suffix: `yrs`,
                  min: 50,
                  max: 70,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...((tempAnswers.p_monthly_db_pension || 0) > 0
          ? ([
              {
                key: 'p_future_db_pension_increases',
                type: 'number',
                title: {
                  label: `What is the expected future annual increase in the DB pension once payments start?`,

                  helpText: {
                    key: 'p_future_db_pension_increases_help',
                    text: `Enter as a percentage of the change in the Consumer Price Index (CPI). For example, enter 50% if your DB pension will increase annually by 50% of the change in CPI.`,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.p_future_db_pension_increases,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    p_future_db_pension_increases: val,
                  });
                },
                props: {
                  suffix: `%`,
                  min: 0,
                  max: 100,
                  decimalScale: 2,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
      ],
      validation: (ans: SurveyAnswersType) => {
        const temp: ValidationReturn = {};
        if ((ans.p_current_age || 0) >= 60) {
          if (!ans.p_monthly_cpp_pension && ans.p_monthly_cpp_pension !== 0) {
            temp.p_monthly_cpp_pension = 'Please enter a value.';
          }

          if (ans.p_monthly_cpp_pension !== undefined) {
            if (ans.p_monthly_cpp_pension < 0) {
              temp.p_monthly_cpp_pension = 'Please enter a value >= 0.';
            }
          }
        }

        if ((ans.p_monthly_cpp_pension || 0) === 0) {
          if (
            !ans.p_expected_future_cpp_pension_perc &&
            ans.p_expected_future_cpp_pension_perc !== 0
          ) {
            temp.p_expected_future_cpp_pension_perc = 'Please enter a value.';
          }

          if (ans.p_expected_future_cpp_pension_perc !== undefined) {
            if (ans.p_expected_future_cpp_pension_perc < 0) {
              temp.p_expected_future_cpp_pension_perc =
                'Please enter a value >= 0.';
            }
          }

          if (ans.p_expected_future_cpp_pension_perc !== undefined) {
            if (ans.p_expected_future_cpp_pension_perc > 100) {
              temp.p_expected_future_cpp_pension_perc =
                'Please enter a value <= 100.';
            }
          }
        }

        if (isSubscribed && ans.p_monthly_cpp_pension === 0) {
          if (!['y', 'n'].includes(ans.p_cpp_start_age_toggle)) {
            temp.p_cpp_start_age_toggle = SURVEY_ERRORS.p_cpp_start_age_toggle;
          }
        }

        if (
          isSubscribed &&
          ans.p_cpp_start_age_toggle === 'n' &&
          ans.p_monthly_cpp_pension === 0
        ) {
          if (
            !ans.p_cpp_start_age_override &&
            ans.p_cpp_start_age_override !== 0
          ) {
            temp.p_cpp_start_age_override = 'Please enter a value.';
          }

          if (ans.p_cpp_start_age_override !== undefined) {
            if (ans.p_cpp_start_age_override < 60) {
              temp.p_cpp_start_age_override = 'Please enter a value >= 60.';
            }
          }

          if (ans.p_cpp_start_age_override !== undefined) {
            if (ans.p_cpp_start_age_override > 70) {
              temp.p_cpp_start_age_override = 'Please enter a value <= 70.';
            }
          }
        }

        if ((ans.p_current_age || 0) >= 65) {
          if (!ans.p_monthly_oas_pension && ans.p_monthly_oas_pension !== 0) {
            temp.p_monthly_oas_pension = 'Please enter a value.';
          }

          if (ans.p_monthly_oas_pension !== undefined) {
            if (ans.p_monthly_oas_pension < 0) {
              temp.p_monthly_oas_pension = 'Please enter a value >= 0.';
            }
          }
        }

        if (isSubscribed && ans.p_monthly_oas_pension === 0) {
          if (!['y', 'n'].includes(ans.p_oas_start_age_toggle)) {
            temp.p_oas_start_age_toggle = SURVEY_ERRORS.p_oas_start_age_toggle;
          }
        }

        if (
          isSubscribed &&
          ans.p_oas_start_age_toggle === 'n' &&
          ans.p_monthly_oas_pension === 0
        ) {
          if (
            !ans.p_oas_start_age_override &&
            ans.p_oas_start_age_override !== 0
          ) {
            temp.p_oas_start_age_override = 'Please enter a value.';
          }

          if (ans.p_oas_start_age_override !== undefined) {
            if (ans.p_oas_start_age_override < 65) {
              temp.p_oas_start_age_override = 'Please enter a value >= 65.';
            }
          }

          if (ans.p_oas_start_age_override !== undefined) {
            if (ans.p_oas_start_age_override > 70) {
              temp.p_oas_start_age_override = 'Please enter a value <= 70.';
            }
          }
        }

        if (!ans.p_current_rrsp_rrif && ans.p_current_rrsp_rrif !== 0) {
          temp.p_current_rrsp_rrif = 'Please enter a value.';
        }

        if (ans.p_current_rrsp_rrif !== undefined) {
          if (ans.p_current_rrsp_rrif < 0) {
            temp.p_current_rrsp_rrif = 'Please enter a value >= 0.';
          }
        }

        if (
          !ans.p_current_dc_pension_lif &&
          ans.p_current_dc_pension_lif !== 0
        ) {
          temp.p_current_dc_pension_lif = 'Please enter a value.';
        }

        if (ans.p_current_dc_pension_lif !== undefined) {
          if (ans.p_current_dc_pension_lif < 0) {
            temp.p_current_dc_pension_lif = 'Please enter a value >= 0.';
          }
        }

        if (!ans.p_current_tfsa && ans.p_current_tfsa !== 0) {
          temp.p_current_tfsa = 'Please enter a value.';
        }

        if (ans.p_current_tfsa !== undefined) {
          if (ans.p_current_tfsa < 0) {
            temp.p_current_tfsa = 'Please enter a value >= 0.';
          }
        }

        if (!ans.p_current_nts_assets && ans.p_current_nts_assets !== 0) {
          temp.p_current_nts_assets = 'Please enter a value.';
        }

        if (ans.p_current_nts_assets !== undefined) {
          if (ans.p_current_nts_assets < 0) {
            temp.p_current_nts_assets = 'Please enter a value >= 0.';
          }
        }

        if (!ans.p_monthly_db_pension && ans.p_monthly_db_pension !== 0) {
          temp.p_monthly_db_pension = 'Please enter a value.';
        }

        if (ans.p_monthly_db_pension !== undefined) {
          if (ans.p_monthly_db_pension < 0) {
            temp.p_monthly_db_pension = 'Please enter a value >= 0.';
          }
        }

        if ((ans.p_monthly_db_pension || 0) > 0) {
          if (!ans.p_db_pension_start_age && ans.p_db_pension_start_age !== 0) {
            temp.p_db_pension_start_age = 'Please enter a value.';
          }

          if (ans.p_db_pension_start_age !== undefined) {
            if (ans.p_db_pension_start_age < 50) {
              temp.p_db_pension_start_age = 'Please enter a value >= 50.';
            }
          }

          if (ans.p_db_pension_start_age !== undefined) {
            if (ans.p_db_pension_start_age > 70) {
              temp.p_db_pension_start_age = 'Please enter a value <= 70.';
            }
          }
        }

        if ((ans.p_monthly_db_pension || 0) > 0) {
          if (
            !ans.p_future_db_pension_increases &&
            ans.p_future_db_pension_increases !== 0
          ) {
            temp.p_future_db_pension_increases = 'Please enter a value.';
          }

          if (ans.p_future_db_pension_increases !== undefined) {
            if (ans.p_future_db_pension_increases < 0) {
              temp.p_future_db_pension_increases = 'Please enter a value >= 0.';
            }
          }

          if (ans.p_future_db_pension_increases !== undefined) {
            if (ans.p_future_db_pension_increases > 100) {
              temp.p_future_db_pension_increases =
                'Please enter a value <= 100.';
            }
          }

          if (ans.p_future_db_pension_increases !== undefined) {
            if (
              ans.p_future_db_pension_increases < 20 &&
              ans.p_future_db_pension_increases !== 0
            ) {
              temp.p_future_db_pension_increases =
                'The annual increase, if any, must be at least 20% of the inflation rate.';
            }
          }
        }
        return temp;
      },
    },

    // MARK: Page 4
    {
      key: 'your_employment_income',
      pageType: 'standard',
      title: `Your Employment Income`,
      questions: (
        tempAnswers: SurveyAnswersType,
        updateAnswers: (val: SurveyAnswersType) => void,
      ) => [
        {
          key: 'p_pre_retire_work_years',
          type: 'number',
          title: {
            label: `How many more years will you work full-time?`,
          },
          stackType: 'STACKED',
          value: tempAnswers.p_pre_retire_work_years,
          updateValue: (val?: number) => {
            updateAnswers({
              ...tempAnswers,
              p_pre_retire_work_years: val,
              ...((val || 0) === 0
                ? {
                    p_pre_retire_self_employed:
                      SurveyDefaultAnswers.p_pre_retire_self_employed,
                    p_pre_retire_annual_pay:
                      SurveyDefaultAnswers.p_pre_retire_annual_pay,
                    p_pre_retire_rrsp: SurveyDefaultAnswers.p_pre_retire_rrsp,
                    p_pre_retire_tfsa: SurveyDefaultAnswers.p_pre_retire_tfsa,
                    p_pre_retire_you_dc:
                      SurveyDefaultAnswers.p_pre_retire_you_dc,
                    p_pre_retire_employer_dc:
                      SurveyDefaultAnswers.p_pre_retire_employer_dc,
                    p_pre_retire_db: SurveyDefaultAnswers.p_pre_retire_db,
                  }
                : {}),
            });
          },
          props: {
            suffix: `yrs`,
            min: 0,
            max: 50,
            decimalScale: 0,
          },
        },
        ...((tempAnswers.p_pre_retire_work_years || 0) > 0
          ? ([
              {
                key: 'p_pre_retire_title',
                type: 'title',
                title: {
                  label: `Pre-Retirement Income`,

                  size: FONT_SIZE.PX20,
                  font: FONTS.BOLD,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...((tempAnswers.p_pre_retire_work_years || 0) > 0
          ? ([
              {
                key: 'p_pre_retire_self_employed',
                type: 'radio',
                title: {
                  label: `Are you self-employed?`,
                },
                stackType: 'STACKED',
                value: tempAnswers.p_pre_retire_self_employed,
                updateValue: (val: string) => {
                  updateAnswers({
                    ...tempAnswers,
                    p_pre_retire_self_employed: val,
                  });
                },
                props: {
                  options: [
                    { key: 'y', label: 'Yes' },
                    { key: 'n', label: 'No' },
                  ],
                  direction: 'row',
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...((tempAnswers.p_pre_retire_work_years || 0) > 0
          ? ([
              {
                key: 'p_pre_retire_annual_pay',
                type: 'number',
                title: {
                  label: `What is your current annual pay before tax?`,
                },
                stackType: 'STACKED',
                value: tempAnswers.p_pre_retire_annual_pay,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    p_pre_retire_annual_pay: val,
                  });
                },
                props: {
                  suffix: `CAD`,
                  min: 0,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed && (tempAnswers.p_pre_retire_work_years || 0) > 1
          ? ([
              {
                key: 'p_pre_retire_full_time_incr',
                type: 'number',
                title: {
                  label: `What is the expected future annual increase in full-time pay?`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 'p_pre_retire_full_time_incr',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],
                },
                stackType: 'STACKED',
                value: tempAnswers.p_pre_retire_full_time_incr,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    p_pre_retire_full_time_incr: val,
                  });
                },
                props: {
                  disabled: !isSubscribed,

                  suffix: `%`,
                  min: -10,
                  max: 10,
                  decimalScale: 2,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...((tempAnswers.p_pre_retire_work_years || 0) > 0
          ? ([
              {
                key: 'p_pre_retire_amount_spacer',
                type: 'spacer',
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...((tempAnswers.p_pre_retire_work_years || 0) > 0
          ? ([
              {
                key: 'p_pre_retire_amount_text',
                type: 'title',
                title: {
                  label: `Until you retire, what percentage of pay do you plan to contribute?`,
                  color: COLORS.MAIN,
                  size: FONT_SIZE.PX16,
                  font: FONTS.MEDIUM,
                  bottomPadding: '0px',
                },
                hideBottomPadding: true,
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...((tempAnswers.p_pre_retire_work_years || 0) > 0
          ? ([
              {
                key: 'p_pre_retire_rrsp',
                type: 'number',
                title: {
                  label: `Contribution to an RRSP`,
                },
                stackType: 'STACKED',
                value: tempAnswers.p_pre_retire_rrsp,
                updateValue: (val?: number) => {
                  updateAnswers({ ...tempAnswers, p_pre_retire_rrsp: val });
                },
                props: {
                  suffix: `%`,
                  min: 0,
                  max: 100,
                  decimalScale: 2,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed && (tempAnswers.p_pre_retire_work_years || 0) > 0
          ? ([
              {
                key: 'p_pre_retire_use_max_tfsa',
                type: 'radio',
                title: {
                  label: `Do you want to contribute the max TFSA amount rather than a percentage of your full-time pay?`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 'p_pre_retire_use_max_tfsa',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],
                },
                stackType: 'STACKED',
                value: tempAnswers.p_pre_retire_use_max_tfsa,
                updateValue: (val: string) => {
                  updateAnswers({
                    ...tempAnswers,
                    p_pre_retire_use_max_tfsa: val,
                    ...(val === 'y'
                      ? {
                          p_pre_retire_tfsa:
                            SurveyDefaultAnswers.p_pre_retire_tfsa,
                        }
                      : {}),
                  });
                },
                props: {
                  options: [
                    { key: 'y', label: 'Yes' },
                    { key: 'n', label: 'No' },
                  ],
                  direction: 'row',
                  disabled: !isSubscribed,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...((tempAnswers.p_pre_retire_work_years || 0) > 0 &&
        tempAnswers.p_pre_retire_use_max_tfsa === 'n'
          ? ([
              {
                key: 'p_pre_retire_tfsa',
                type: 'number',
                title: {
                  label: `Contribution to a TFSA`,
                },
                stackType: 'STACKED',
                value: tempAnswers.p_pre_retire_tfsa,
                updateValue: (val?: number) => {
                  updateAnswers({ ...tempAnswers, p_pre_retire_tfsa: val });
                },
                props: {
                  suffix: `%`,
                  min: 0,
                  max: 100,
                  decimalScale: 2,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...((tempAnswers.p_pre_retire_work_years || 0) > 0
          ? ([
              {
                key: 'p_pre_retire_you_dc',
                type: 'number',
                title: {
                  label: `Employee contribution to a DC Pension Plan`,
                },
                stackType: 'STACKED',
                value: tempAnswers.p_pre_retire_you_dc,
                updateValue: (val?: number) => {
                  updateAnswers({ ...tempAnswers, p_pre_retire_you_dc: val });
                },
                props: {
                  suffix: `%`,
                  min: 0,
                  max: 100,
                  decimalScale: 2,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...((tempAnswers.p_pre_retire_work_years || 0) > 0
          ? ([
              {
                key: 'p_pre_retire_employer_dc',
                type: 'number',
                title: {
                  label: `Employer contribution to a DC Pension Plan`,
                },
                stackType: 'STACKED',
                value: tempAnswers.p_pre_retire_employer_dc,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    p_pre_retire_employer_dc: val,
                  });
                },
                props: {
                  suffix: `%`,
                  min: 0,
                  max: 100,
                  decimalScale: 2,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...((tempAnswers.p_pre_retire_work_years || 0) > 0
          ? ([
              {
                key: 'p_pre_retire_db',
                type: 'number',
                title: {
                  label: `Contribution to a DB Pension Plan`,
                },
                stackType: 'STACKED',
                value: tempAnswers.p_pre_retire_db,
                updateValue: (val?: number) => {
                  updateAnswers({ ...tempAnswers, p_pre_retire_db: val });
                },
                props: {
                  suffix: `%`,
                  min: 0,
                  max: 100,
                  decimalScale: 2,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        {
          key: 'p_expect_future_earnings',
          type: 'radio',
          title: {
            label: `Will you be paid for part-time or contract work after you retire?`,
          },
          stackType: 'STACKED',
          value: tempAnswers.p_expect_future_earnings,
          updateValue: (val: string) => {
            updateAnswers({
              ...tempAnswers,
              p_expect_future_earnings: val,
              ...(val === 'n'
                ? {
                    p_future_part_time_pay:
                      SurveyDefaultAnswers.p_future_part_time_pay,
                    p_yrs_part_time_pay:
                      SurveyDefaultAnswers.p_yrs_part_time_pay,
                  }
                : {}),
            });
          },
          props: {
            options: [
              { key: 'y', label: 'Yes' },
              { key: 'n', label: 'No' },
            ],
            direction: 'row',
          },
        },
        ...(tempAnswers.p_expect_future_earnings === 'y'
          ? ([
              {
                key: 'p_future_earnings_title',
                type: 'title',
                title: {
                  label: `Post-Retirement Income`,

                  size: FONT_SIZE.PX20,
                  font: FONTS.BOLD,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(tempAnswers.p_expect_future_earnings === 'y'
          ? ([
              {
                key: 'p_future_part_time_pay',
                type: 'number',
                title: {
                  label: `What do you expect to earn in each future year from part-time or contract work?`,

                  helpText: {
                    key: 'p_future_part_time_pay_help',
                    text: `This should be shown as a gross annual amount, before taxes and deductions. If it varies, enter the average amount. `,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.p_future_part_time_pay,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    p_future_part_time_pay: val,
                  });
                },
                props: {
                  suffix: `CAD`,
                  min: 0,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(tempAnswers.p_expect_future_earnings === 'y'
          ? ([
              {
                key: 'p_yrs_part_time_pay',
                type: 'number',
                title: {
                  label: `How many years do you expect to have part-time or contract work?`,

                  helpText: {
                    key: 'p_yrs_part_time_pay_help',
                    text: `Only future years, not past years. `,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.p_yrs_part_time_pay,
                updateValue: (val?: number) => {
                  updateAnswers({ ...tempAnswers, p_yrs_part_time_pay: val });
                },
                props: {
                  suffix: `yrs`,
                  min: 0,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
      ],
      validation: (ans: SurveyAnswersType) => {
        const temp: ValidationReturn = {};

        if (!ans.p_pre_retire_work_years && ans.p_pre_retire_work_years !== 0) {
          temp.p_pre_retire_work_years = 'Please enter a value.';
        }

        if (ans.p_pre_retire_work_years !== undefined) {
          if (ans.p_pre_retire_work_years < 0) {
            temp.p_pre_retire_work_years = 'Please enter a value >= 0.';
          }
        }

        if (ans.p_pre_retire_work_years !== undefined) {
          if (ans.p_pre_retire_work_years > 50) {
            temp.p_pre_retire_work_years = 'Please enter a value <= 50.';
          }
        }

        if ((ans.p_pre_retire_work_years || 0) > 0) {
          if (!['y', 'n'].includes(ans.p_pre_retire_self_employed)) {
            temp.p_pre_retire_self_employed =
              SURVEY_ERRORS.p_pre_retire_self_employed;
          }
        }

        if ((ans.p_pre_retire_work_years || 0) > 0) {
          if (
            !ans.p_pre_retire_annual_pay &&
            ans.p_pre_retire_annual_pay !== 0
          ) {
            temp.p_pre_retire_annual_pay = 'Please enter a value.';
          }

          if (ans.p_pre_retire_annual_pay !== undefined) {
            if (ans.p_pre_retire_annual_pay < 0) {
              temp.p_pre_retire_annual_pay = 'Please enter a value >= 0.';
            }
          }
        }

        if (isSubscribed && (ans.p_pre_retire_work_years || 0) > 1) {
          if (
            !ans.p_pre_retire_full_time_incr &&
            ans.p_pre_retire_full_time_incr !== 0
          ) {
            temp.p_pre_retire_full_time_incr = 'Please enter a value.';
          }

          if (ans.p_pre_retire_full_time_incr !== undefined) {
            if (ans.p_pre_retire_full_time_incr < -10) {
              temp.p_pre_retire_full_time_incr = 'Please enter a value >= -10.';
            }
          }

          if (ans.p_pre_retire_full_time_incr !== undefined) {
            if (ans.p_pre_retire_full_time_incr > 10) {
              temp.p_pre_retire_full_time_incr = 'Please enter a value <= 10.';
            }
          }
        }

        if ((ans.p_pre_retire_work_years || 0) > 0) {
          if (!ans.p_pre_retire_rrsp && ans.p_pre_retire_rrsp !== 0) {
            temp.p_pre_retire_rrsp = 'Please enter a value.';
          }

          if (ans.p_pre_retire_rrsp !== undefined) {
            if (ans.p_pre_retire_rrsp < 0) {
              temp.p_pre_retire_rrsp = 'Please enter a value >= 0.';
            }
          }

          if (ans.p_pre_retire_rrsp !== undefined) {
            if (ans.p_pre_retire_rrsp > 100) {
              temp.p_pre_retire_rrsp = 'Please enter a value <= 100.';
            }
          }
        }

        if (isSubscribed && (ans.p_pre_retire_work_years || 0) > 0) {
          if (!['y', 'n'].includes(ans.p_pre_retire_use_max_tfsa)) {
            temp.p_pre_retire_use_max_tfsa =
              SURVEY_ERRORS.p_pre_retire_use_max_tfsa;
          }
        }

        if (
          (ans.p_pre_retire_work_years || 0) > 0 &&
          ans.p_pre_retire_use_max_tfsa === 'n'
        ) {
          if (!ans.p_pre_retire_tfsa && ans.p_pre_retire_tfsa !== 0) {
            temp.p_pre_retire_tfsa = 'Please enter a value.';
          }

          if (ans.p_pre_retire_tfsa !== undefined) {
            if (ans.p_pre_retire_tfsa < 0) {
              temp.p_pre_retire_tfsa = 'Please enter a value >= 0.';
            }
          }

          if (ans.p_pre_retire_tfsa !== undefined) {
            if (ans.p_pre_retire_tfsa > 100) {
              temp.p_pre_retire_tfsa = 'Please enter a value <= 100.';
            }
          }
        }

        if ((ans.p_pre_retire_work_years || 0) > 0) {
          if (!ans.p_pre_retire_you_dc && ans.p_pre_retire_you_dc !== 0) {
            temp.p_pre_retire_you_dc = 'Please enter a value.';
          }

          if (ans.p_pre_retire_you_dc !== undefined) {
            if (ans.p_pre_retire_you_dc < 0) {
              temp.p_pre_retire_you_dc = 'Please enter a value >= 0.';
            }
          }

          if (ans.p_pre_retire_you_dc !== undefined) {
            if (ans.p_pre_retire_you_dc > 100) {
              temp.p_pre_retire_you_dc = 'Please enter a value <= 100.';
            }
          }
        }

        if ((ans.p_pre_retire_work_years || 0) > 0) {
          if (
            !ans.p_pre_retire_employer_dc &&
            ans.p_pre_retire_employer_dc !== 0
          ) {
            temp.p_pre_retire_employer_dc = 'Please enter a value.';
          }

          if (ans.p_pre_retire_employer_dc !== undefined) {
            if (ans.p_pre_retire_employer_dc < 0) {
              temp.p_pre_retire_employer_dc = 'Please enter a value >= 0.';
            }
          }

          if (ans.p_pre_retire_employer_dc !== undefined) {
            if (ans.p_pre_retire_employer_dc > 100) {
              temp.p_pre_retire_employer_dc = 'Please enter a value <= 100.';
            }
          }
        }

        if ((ans.p_pre_retire_work_years || 0) > 0) {
          if (!ans.p_pre_retire_db && ans.p_pre_retire_db !== 0) {
            temp.p_pre_retire_db = 'Please enter a value.';
          }

          if (ans.p_pre_retire_db !== undefined) {
            if (ans.p_pre_retire_db < 0) {
              temp.p_pre_retire_db = 'Please enter a value >= 0.';
            }
          }

          if (ans.p_pre_retire_db !== undefined) {
            if (ans.p_pre_retire_db > 100) {
              temp.p_pre_retire_db = 'Please enter a value <= 100.';
            }
          }
        }

        if (!['y', 'n'].includes(ans.p_expect_future_earnings)) {
          temp.p_expect_future_earnings =
            SURVEY_ERRORS.p_expect_future_earnings;
        }

        if (ans.p_expect_future_earnings === 'y') {
          if (!ans.p_future_part_time_pay && ans.p_future_part_time_pay !== 0) {
            temp.p_future_part_time_pay = 'Please enter a value.';
          }

          if (ans.p_future_part_time_pay !== undefined) {
            if (ans.p_future_part_time_pay < 0) {
              temp.p_future_part_time_pay = 'Please enter a value >= 0.';
            }
          }
        }

        if (ans.p_expect_future_earnings === 'y') {
          if (!ans.p_yrs_part_time_pay && ans.p_yrs_part_time_pay !== 0) {
            temp.p_yrs_part_time_pay = 'Please enter a value.';
          }

          if (ans.p_yrs_part_time_pay !== undefined) {
            if (ans.p_yrs_part_time_pay < 0) {
              temp.p_yrs_part_time_pay = 'Please enter a value >= 0.';
            }
          }
        }
        return temp;
      },
    },

    // MARK: Page 5
    {
      key: 'your_spouses_assets',
      pageType: 'standard',
      title: `Your Spouse's Assets`,
      questions: (
        tempAnswers: SurveyAnswersType,
        updateAnswers: (val: SurveyAnswersType) => void,
      ) => [
        ...(answers.include_spouse === 'y' && (answers.s_current_age || 0) >= 60
          ? ([
              {
                key: 's_monthly_cpp_pension',
                type: 'number',
                title: {
                  label: `How much C/QPP pension is your spouse currently receiving each month?`,

                  helpText: {
                    key: 's_monthly_cpp_pension_help',
                    text: `If C/QPP pension has not yet started, leave this blank. `,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.s_monthly_cpp_pension,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    s_monthly_cpp_pension: val,
                    ...((val || 0) > 0
                      ? {
                          s_expected_future_cpp_pension_perc:
                            SurveyDefaultAnswers.s_expected_future_cpp_pension_perc,
                        }
                      : {}),
                  });
                },
                props: {
                  suffix: `CAD`,
                  min: 0,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(answers.include_spouse === 'y' &&
        (tempAnswers.s_monthly_cpp_pension || 0) === 0
          ? ([
              {
                key: 's_expected_future_cpp_pension_perc',
                type: 'number',
                title: {
                  label: `What is your spouse's expected future CPP pension (as a percentage of the maximum)?`,
                },
                stackType: 'STACKED',
                value: tempAnswers.s_expected_future_cpp_pension_perc,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    s_expected_future_cpp_pension_perc: val,
                  });
                },
                props: {
                  suffix: `%`,
                  min: 0,
                  max: 100,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed &&
        answers.include_spouse === 'y' &&
        tempAnswers.s_monthly_cpp_pension === 0
          ? ([
              {
                key: 's_cpp_start_age_toggle',
                type: 'radio',
                title: {
                  label: `Do you want PERC to choose your spouse's optimal CPP Starting Age?`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 's_cpp_start_age_toggle',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],

                  helpText: {
                    key: 's_cpp_start_age_toggle_help',
                    text: `If you answer 'no' then PERC will calculate the optimal starting age for your spouse.`,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.s_cpp_start_age_toggle,
                updateValue: (val: string) => {
                  updateAnswers({
                    ...tempAnswers,
                    s_cpp_start_age_toggle: val,
                    ...(val === 'y'
                      ? {
                          s_cpp_start_age_override:
                            SurveyDefaultAnswers.s_cpp_start_age_override,
                        }
                      : {}),
                  });
                },
                props: {
                  options: [
                    { key: 'y', label: 'Yes' },
                    { key: 'n', label: 'No' },
                  ],
                  direction: 'row',
                  disabled: !isSubscribed,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed &&
        answers.include_spouse === 'y' &&
        tempAnswers.s_cpp_start_age_toggle === 'n' &&
        tempAnswers.s_monthly_cpp_pension === 0
          ? ([
              {
                key: 's_cpp_start_age_override',
                type: 'number',
                title: {
                  label: `What age would you like your spouse's CPP to start?`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 's_cpp_start_age_override',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],
                },
                stackType: 'STACKED',
                value: tempAnswers.s_cpp_start_age_override,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    s_cpp_start_age_override: val,
                  });
                },
                props: {
                  disabled: !isSubscribed,

                  min: 60,
                  max: 70,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(answers.include_spouse === 'y' && (answers.s_current_age || 0) >= 65
          ? ([
              {
                key: 's_monthly_oas_pension',
                type: 'number',
                title: {
                  label: `How much monthly OAS pension is your spouse currently receiving?`,

                  helpText: {
                    key: 's_monthly_oas_pension_help',
                    text: `If your spouse's OAS pension has not yet started, leave this blank. `,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.s_monthly_oas_pension,
                updateValue: (val?: number) => {
                  updateAnswers({ ...tempAnswers, s_monthly_oas_pension: val });
                },
                props: {
                  suffix: `CAD`,
                  min: 0,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed &&
        answers.include_spouse === 'y' &&
        tempAnswers.s_monthly_oas_pension === 0
          ? ([
              {
                key: 's_oas_start_age_toggle',
                type: 'radio',
                title: {
                  label: `Do you want PERC to choose your spouse's optimal OAS Starting Age?`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 's_oas_start_age_toggle',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],

                  helpText: {
                    key: 's_oas_start_age_toggle_help',
                    text: `If you answer 'no' then PERC will calculate the optimal starting age for your spouse.`,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.s_oas_start_age_toggle,
                updateValue: (val: string) => {
                  updateAnswers({
                    ...tempAnswers,
                    s_oas_start_age_toggle: val,
                    ...(val === 'y'
                      ? {
                          s_oas_start_age_override:
                            SurveyDefaultAnswers.s_oas_start_age_override,
                        }
                      : {}),
                  });
                },
                props: {
                  options: [
                    { key: 'y', label: 'Yes' },
                    { key: 'n', label: 'No' },
                  ],
                  direction: 'row',
                  disabled: !isSubscribed,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed &&
        answers.include_spouse === 'y' &&
        tempAnswers.s_oas_start_age_toggle === 'n' &&
        tempAnswers.s_monthly_oas_pension === 0
          ? ([
              {
                key: 's_oas_start_age_override',
                type: 'number',
                title: {
                  label: `What age would you like your spouse's OAS to start?`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 's_oas_start_age_override',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],
                },
                stackType: 'STACKED',
                value: tempAnswers.s_oas_start_age_override,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    s_oas_start_age_override: val,
                  });
                },
                props: {
                  disabled: !isSubscribed,

                  min: 65,
                  max: 70,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(answers.include_spouse === 'y'
          ? ([
              {
                key: 's_current_rrsp_rrif',
                type: 'number',
                title: {
                  label: `How much does your spouse currently have in RRSPs and RRIFs combined?`,

                  helpText: {
                    key: 's_current_rrsp_rrif_help',
                    text: `Do not double-count your family assets.`,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.s_current_rrsp_rrif,
                updateValue: (val?: number) => {
                  updateAnswers({ ...tempAnswers, s_current_rrsp_rrif: val });
                },
                props: {
                  suffix: `CAD`,
                  min: 0,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(answers.include_spouse === 'y'
          ? ([
              {
                key: 's_current_dc_pension_lif',
                type: 'number',
                title: {
                  label: `How much does your spouse currently have in DC pension plans and LIFs combined?`,

                  helpText: {
                    key: 's_current_dc_pension_lif_help',
                    text: `Do not double-count your family assets.`,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.s_current_dc_pension_lif,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    s_current_dc_pension_lif: val,
                  });
                },
                props: {
                  suffix: `CAD`,
                  min: 0,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(answers.include_spouse === 'y'
          ? ([
              {
                key: 's_current_tfsa',
                type: 'number',
                title: {
                  label: `How much does your spouse currently have in a TFSA?`,

                  helpText: {
                    key: 's_current_tfsa_help',
                    text: `Do not double-count your family assets.`,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.s_current_tfsa,
                updateValue: (val?: number) => {
                  updateAnswers({ ...tempAnswers, s_current_tfsa: val });
                },
                props: {
                  suffix: `CAD`,
                  min: 0,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(answers.include_spouse === 'y'
          ? ([
              {
                key: 's_current_nts_assets',
                type: 'number',
                title: {
                  label: `How much does your spouse currently have in financial assets that are not tax-sheltered?`,

                  helpText: {
                    key: 's_current_nts_assets_help',
                    text: `Do not double-count your family assets.`,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.s_current_nts_assets,
                updateValue: (val?: number) => {
                  updateAnswers({ ...tempAnswers, s_current_nts_assets: val });
                },
                props: {
                  suffix: `CAD`,
                  min: 0,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(answers.include_spouse === 'y'
          ? ([
              {
                key: 's_monthly_db_pension',
                type: 'number',
                title: {
                  label: `How much monthly income do you expect your spouse to receive from a workplace DB pension plan (not CPP)?`,
                },
                stackType: 'STACKED',
                value: tempAnswers.s_monthly_db_pension,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    s_monthly_db_pension: val,
                    ...((val || 0) === 0
                      ? {
                          s_db_pension_start_age:
                            SurveyDefaultAnswers.s_db_pension_start_age,
                          s_future_db_pension_increases:
                            SurveyDefaultAnswers.s_future_db_pension_increases,
                        }
                      : {}),
                  });
                },
                props: {
                  suffix: `CAD`,
                  min: 0,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(answers.include_spouse === 'y' &&
        (tempAnswers.s_monthly_db_pension || 0) > 6000
          ? ([
              {
                key: 's_monthly_db_pension_warning',
                type: 'rawText',
                value: [
                  {
                    text: `WARNING: The DB pension value that you have entered is abnormally high, which could lead to invalid results. Please ensure that you are entering a monthly value.`,
                    color: COLORS.DARK_RED,
                  },
                ],
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(answers.include_spouse === 'y' &&
        (tempAnswers.s_monthly_db_pension || 0) > 0
          ? ([
              {
                key: 's_db_pension_start_age',
                type: 'number',
                title: {
                  label: `What age will your spouse's DB pension start?`,

                  helpText: {
                    key: 's_db_pension_start_age_help',
                    text: `If the DB pension has already started, enter current age. `,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.s_db_pension_start_age,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    s_db_pension_start_age: val,
                  });
                },
                props: {
                  suffix: `yrs`,
                  min: 50,
                  max: 70,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(answers.include_spouse === 'y' &&
        (tempAnswers.s_monthly_db_pension || 0) > 0
          ? ([
              {
                key: 's_future_db_pension_increases',
                type: 'number',
                title: {
                  label: `What is the expected future annual increase in your spouse's DB pension once payments start?`,

                  helpText: {
                    key: 's_future_db_pension_increases_help',
                    text: `Enter as a percentage of future changes in CPI (e.g. 50%). `,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.s_future_db_pension_increases,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    s_future_db_pension_increases: val,
                  });
                },
                props: {
                  suffix: `%`,
                  min: 0,
                  max: 100,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
      ],
      validation: (ans: SurveyAnswersType) => {
        const temp: ValidationReturn = {};
        if (ans.include_spouse === 'y' && (ans.s_current_age || 0) >= 60) {
          if (!ans.s_monthly_cpp_pension && ans.s_monthly_cpp_pension !== 0) {
            temp.s_monthly_cpp_pension = 'Please enter a value.';
          }

          if (ans.s_monthly_cpp_pension !== undefined) {
            if (ans.s_monthly_cpp_pension < 0) {
              temp.s_monthly_cpp_pension = 'Please enter a value >= 0.';
            }
          }
        }

        if (
          ans.include_spouse === 'y' &&
          (ans.s_monthly_cpp_pension || 0) === 0
        ) {
          if (
            !ans.s_expected_future_cpp_pension_perc &&
            ans.s_expected_future_cpp_pension_perc !== 0
          ) {
            temp.s_expected_future_cpp_pension_perc = 'Please enter a value.';
          }

          if (ans.s_expected_future_cpp_pension_perc !== undefined) {
            if (ans.s_expected_future_cpp_pension_perc < 0) {
              temp.s_expected_future_cpp_pension_perc =
                'Please enter a value >= 0.';
            }
          }

          if (ans.s_expected_future_cpp_pension_perc !== undefined) {
            if (ans.s_expected_future_cpp_pension_perc > 100) {
              temp.s_expected_future_cpp_pension_perc =
                'Please enter a value <= 100.';
            }
          }
        }

        if (
          isSubscribed &&
          ans.include_spouse === 'y' &&
          ans.s_monthly_cpp_pension === 0
        ) {
          if (!['y', 'n'].includes(ans.s_cpp_start_age_toggle)) {
            temp.s_cpp_start_age_toggle = SURVEY_ERRORS.s_cpp_start_age_toggle;
          }
        }

        if (
          isSubscribed &&
          ans.include_spouse === 'y' &&
          ans.s_cpp_start_age_toggle === 'n' &&
          ans.s_monthly_cpp_pension === 0
        ) {
          if (
            !ans.s_cpp_start_age_override &&
            ans.s_cpp_start_age_override !== 0
          ) {
            temp.s_cpp_start_age_override = 'Please enter a value.';
          }

          if (ans.s_cpp_start_age_override !== undefined) {
            if (ans.s_cpp_start_age_override < 60) {
              temp.s_cpp_start_age_override = 'Please enter a value >= 60.';
            }
          }

          if (ans.s_cpp_start_age_override !== undefined) {
            if (ans.s_cpp_start_age_override > 70) {
              temp.s_cpp_start_age_override = 'Please enter a value <= 70.';
            }
          }
        }

        if (ans.include_spouse === 'y' && (ans.s_current_age || 0) >= 65) {
          if (!ans.s_monthly_oas_pension && ans.s_monthly_oas_pension !== 0) {
            temp.s_monthly_oas_pension = 'Please enter a value.';
          }

          if (ans.s_monthly_oas_pension !== undefined) {
            if (ans.s_monthly_oas_pension < 0) {
              temp.s_monthly_oas_pension = 'Please enter a value >= 0.';
            }
          }
        }

        if (
          isSubscribed &&
          ans.include_spouse === 'y' &&
          ans.s_monthly_oas_pension === 0
        ) {
          if (!['y', 'n'].includes(ans.s_oas_start_age_toggle)) {
            temp.s_oas_start_age_toggle = SURVEY_ERRORS.s_oas_start_age_toggle;
          }
        }

        if (
          isSubscribed &&
          ans.include_spouse === 'y' &&
          ans.s_oas_start_age_toggle === 'n' &&
          ans.s_monthly_oas_pension === 0
        ) {
          if (
            !ans.s_oas_start_age_override &&
            ans.s_oas_start_age_override !== 0
          ) {
            temp.s_oas_start_age_override = 'Please enter a value.';
          }

          if (ans.s_oas_start_age_override !== undefined) {
            if (ans.s_oas_start_age_override < 65) {
              temp.s_oas_start_age_override = 'Please enter a value >= 65.';
            }
          }

          if (ans.s_oas_start_age_override !== undefined) {
            if (ans.s_oas_start_age_override > 70) {
              temp.s_oas_start_age_override = 'Please enter a value <= 70.';
            }
          }
        }

        if (ans.include_spouse === 'y') {
          if (!ans.s_current_rrsp_rrif && ans.s_current_rrsp_rrif !== 0) {
            temp.s_current_rrsp_rrif = 'Please enter a value.';
          }

          if (ans.s_current_rrsp_rrif !== undefined) {
            if (ans.s_current_rrsp_rrif < 0) {
              temp.s_current_rrsp_rrif = 'Please enter a value >= 0.';
            }
          }
        }

        if (ans.include_spouse === 'y') {
          if (
            !ans.s_current_dc_pension_lif &&
            ans.s_current_dc_pension_lif !== 0
          ) {
            temp.s_current_dc_pension_lif = 'Please enter a value.';
          }

          if (ans.s_current_dc_pension_lif !== undefined) {
            if (ans.s_current_dc_pension_lif < 0) {
              temp.s_current_dc_pension_lif = 'Please enter a value >= 0.';
            }
          }
        }

        if (ans.include_spouse === 'y') {
          if (!ans.s_current_tfsa && ans.s_current_tfsa !== 0) {
            temp.s_current_tfsa = 'Please enter a value.';
          }

          if (ans.s_current_tfsa !== undefined) {
            if (ans.s_current_tfsa < 0) {
              temp.s_current_tfsa = 'Please enter a value >= 0.';
            }
          }
        }

        if (ans.include_spouse === 'y') {
          if (!ans.s_current_nts_assets && ans.s_current_nts_assets !== 0) {
            temp.s_current_nts_assets = 'Please enter a value.';
          }

          if (ans.s_current_nts_assets !== undefined) {
            if (ans.s_current_nts_assets < 0) {
              temp.s_current_nts_assets = 'Please enter a value >= 0.';
            }
          }
        }

        if (ans.include_spouse === 'y') {
          if (!ans.s_monthly_db_pension && ans.s_monthly_db_pension !== 0) {
            temp.s_monthly_db_pension = 'Please enter a value.';
          }

          if (ans.s_monthly_db_pension !== undefined) {
            if (ans.s_monthly_db_pension < 0) {
              temp.s_monthly_db_pension = 'Please enter a value >= 0.';
            }
          }
        }

        if (ans.include_spouse === 'y' && (ans.s_monthly_db_pension || 0) > 0) {
          if (!ans.s_db_pension_start_age && ans.s_db_pension_start_age !== 0) {
            temp.s_db_pension_start_age = 'Please enter a value.';
          }

          if (ans.s_db_pension_start_age !== undefined) {
            if (ans.s_db_pension_start_age < 50) {
              temp.s_db_pension_start_age = 'Please enter a value >= 50.';
            }
          }

          if (ans.s_db_pension_start_age !== undefined) {
            if (ans.s_db_pension_start_age > 70) {
              temp.s_db_pension_start_age = 'Please enter a value <= 70.';
            }
          }
        }

        if (ans.include_spouse === 'y' && (ans.s_monthly_db_pension || 0) > 0) {
          if (
            !ans.s_future_db_pension_increases &&
            ans.s_future_db_pension_increases !== 0
          ) {
            temp.s_future_db_pension_increases = 'Please enter a value.';
          }

          if (ans.s_future_db_pension_increases !== undefined) {
            if (ans.s_future_db_pension_increases < 0) {
              temp.s_future_db_pension_increases = 'Please enter a value >= 0.';
            }
          }

          if (ans.s_future_db_pension_increases !== undefined) {
            if (ans.s_future_db_pension_increases > 100) {
              temp.s_future_db_pension_increases =
                'Please enter a value <= 100.';
            }
          }

          if (ans.s_future_db_pension_increases !== undefined) {
            if (
              ans.s_future_db_pension_increases < 20 &&
              ans.s_future_db_pension_increases !== 0
            ) {
              temp.s_future_db_pension_increases =
                'The annual increase, if any, must be at least 20% of the inflation rate.';
            }
          }
        }
        return temp;
      },
    },

    // MARK: Page 6
    {
      key: 'your_spouses_employment_income',
      pageType: 'standard',
      title: `Your Spouse's Employment Income`,
      questions: (
        tempAnswers: SurveyAnswersType,
        updateAnswers: (val: SurveyAnswersType) => void,
      ) => [
        ...(answers.include_spouse === 'y'
          ? ([
              {
                key: 's_pre_retire_work_years',
                type: 'number',
                title: {
                  label: `How many more years will your spouse work full-time?`,
                },
                stackType: 'STACKED',
                value: tempAnswers.s_pre_retire_work_years,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    s_pre_retire_work_years: val,
                    ...((val || 0) === 0
                      ? {
                          s_pre_retire_self_employed:
                            SurveyDefaultAnswers.s_pre_retire_self_employed,
                          s_pre_retire_annual_pay:
                            SurveyDefaultAnswers.s_pre_retire_annual_pay,
                          s_pre_retire_rrsp:
                            SurveyDefaultAnswers.s_pre_retire_rrsp,
                          s_pre_retire_tfsa:
                            SurveyDefaultAnswers.s_pre_retire_tfsa,
                          s_pre_retire_you_dc:
                            SurveyDefaultAnswers.s_pre_retire_you_dc,
                          s_pre_retire_employer_dc:
                            SurveyDefaultAnswers.s_pre_retire_employer_dc,
                          s_pre_retire_db: SurveyDefaultAnswers.s_pre_retire_db,
                        }
                      : {}),
                  });
                },
                props: {
                  suffix: `yrs`,
                  min: 0,
                  max: 50,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(answers.include_spouse === 'y' &&
        (tempAnswers.s_pre_retire_work_years || 0) > 0
          ? ([
              {
                key: 's_pre_retire_title',
                type: 'title',
                title: {
                  label: `Pre-Retirement Income`,

                  size: FONT_SIZE.PX20,
                  font: FONTS.BOLD,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(answers.include_spouse === 'y' &&
        (tempAnswers.s_pre_retire_work_years || 0) > 0
          ? ([
              {
                key: 's_pre_retire_self_employed',
                type: 'radio',
                title: {
                  label: `Is your spouse self-employed?`,
                },
                stackType: 'STACKED',
                value: tempAnswers.s_pre_retire_self_employed,
                updateValue: (val: string) => {
                  updateAnswers({
                    ...tempAnswers,
                    s_pre_retire_self_employed: val,
                  });
                },
                props: {
                  options: [
                    { key: 'y', label: 'Yes' },
                    { key: 'n', label: 'No' },
                  ],
                  direction: 'row',
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(answers.include_spouse === 'y' &&
        (tempAnswers.s_pre_retire_work_years || 0) > 0
          ? ([
              {
                key: 's_pre_retire_annual_pay',
                type: 'number',
                title: {
                  label: `What is your spouse's current annual pay before tax?`,
                },
                stackType: 'STACKED',
                value: tempAnswers.s_pre_retire_annual_pay,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    s_pre_retire_annual_pay: val,
                  });
                },
                props: {
                  suffix: `CAD`,
                  min: 0,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed &&
        answers.include_spouse === 'y' &&
        (tempAnswers.s_pre_retire_work_years || 0) > 1
          ? ([
              {
                key: 's_pre_retire_full_time_incr',
                type: 'number',
                title: {
                  label: `What is your spouse's expected future annual increase in full-time pay?`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 's_pre_retire_full_time_incr',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],
                },
                stackType: 'STACKED',
                value: tempAnswers.s_pre_retire_full_time_incr,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    s_pre_retire_full_time_incr: val,
                  });
                },
                props: {
                  disabled: !isSubscribed,

                  suffix: `%`,
                  min: -10,
                  max: 10,
                  decimalScale: 2,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(answers.include_spouse === 'y' &&
        (tempAnswers.s_pre_retire_work_years || 0) > 0
          ? ([
              {
                key: 's_pre_retire_amount_spacer',
                type: 'spacer',
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(answers.include_spouse === 'y' &&
        (tempAnswers.s_pre_retire_work_years || 0) > 0
          ? ([
              {
                key: 's_pre_retire_amount_text',
                type: 'title',
                title: {
                  label: `Until you retire, what percentage of your spouse's pay do they plan to contribute?`,
                  color: COLORS.MAIN,
                  size: FONT_SIZE.PX16,
                  font: FONTS.MEDIUM,
                  bottomPadding: '0px',
                },
                hideBottomPadding: true,
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(answers.include_spouse === 'y' &&
        (tempAnswers.s_pre_retire_work_years || 0) > 0
          ? ([
              {
                key: 's_pre_retire_rrsp',
                type: 'number',
                title: {
                  label: `Contribution to an RRSP`,
                },
                stackType: 'STACKED',
                value: tempAnswers.s_pre_retire_rrsp,
                updateValue: (val?: number) => {
                  updateAnswers({ ...tempAnswers, s_pre_retire_rrsp: val });
                },
                props: {
                  suffix: `%`,
                  min: 0,
                  max: 100,
                  decimalScale: 2,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed &&
        answers.include_spouse === 'y' &&
        (tempAnswers.s_pre_retire_work_years || 0) > 0
          ? ([
              {
                key: 's_pre_retire_use_max_tfsa',
                type: 'radio',
                title: {
                  label: `Do you want your spouse to contribute the max TFSA amount rather than a percentage of their full-time pay?`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 's_pre_retire_use_max_tfsa',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],
                },
                stackType: 'STACKED',
                value: tempAnswers.s_pre_retire_use_max_tfsa,
                updateValue: (val: string) => {
                  updateAnswers({
                    ...tempAnswers,
                    s_pre_retire_use_max_tfsa: val,
                    ...(val === 'y'
                      ? {
                          s_pre_retire_tfsa:
                            SurveyDefaultAnswers.s_pre_retire_tfsa,
                        }
                      : {}),
                  });
                },
                props: {
                  options: [
                    { key: 'y', label: 'Yes' },
                    { key: 'n', label: 'No' },
                  ],
                  direction: 'row',
                  disabled: !isSubscribed,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(answers.include_spouse === 'y' &&
        (tempAnswers.s_pre_retire_work_years || 0) > 0 &&
        tempAnswers.s_pre_retire_use_max_tfsa === 'n'
          ? ([
              {
                key: 's_pre_retire_tfsa',
                type: 'number',
                title: {
                  label: `Contribution to a TFSA`,
                },
                stackType: 'STACKED',
                value: tempAnswers.s_pre_retire_tfsa,
                updateValue: (val?: number) => {
                  updateAnswers({ ...tempAnswers, s_pre_retire_tfsa: val });
                },
                props: {
                  suffix: `%`,
                  min: 0,
                  max: 100,
                  decimalScale: 2,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(answers.include_spouse === 'y' &&
        (tempAnswers.s_pre_retire_work_years || 0) > 0
          ? ([
              {
                key: 's_pre_retire_you_dc',
                type: 'number',
                title: {
                  label: `Employee contribution to a DC Pension Plan`,
                },
                stackType: 'STACKED',
                value: tempAnswers.s_pre_retire_you_dc,
                updateValue: (val?: number) => {
                  updateAnswers({ ...tempAnswers, s_pre_retire_you_dc: val });
                },
                props: {
                  suffix: `%`,
                  min: 0,
                  max: 100,
                  decimalScale: 2,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(answers.include_spouse === 'y' &&
        (tempAnswers.s_pre_retire_work_years || 0) > 0
          ? ([
              {
                key: 's_pre_retire_employer_dc',
                type: 'number',
                title: {
                  label: `Employer contribution to a  DC Pension Plan`,
                },
                stackType: 'STACKED',
                value: tempAnswers.s_pre_retire_employer_dc,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    s_pre_retire_employer_dc: val,
                  });
                },
                props: {
                  suffix: `%`,
                  min: 0,
                  max: 100,
                  decimalScale: 2,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(answers.include_spouse === 'y' &&
        (tempAnswers.s_pre_retire_work_years || 0) > 0
          ? ([
              {
                key: 's_pre_retire_db',
                type: 'number',
                title: {
                  label: `Contribution to a DB Pension Plan`,
                },
                stackType: 'STACKED',
                value: tempAnswers.s_pre_retire_db,
                updateValue: (val?: number) => {
                  updateAnswers({ ...tempAnswers, s_pre_retire_db: val });
                },
                props: {
                  suffix: `%`,
                  min: 0,
                  max: 100,
                  decimalScale: 2,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(answers.include_spouse === 'y'
          ? ([
              {
                key: 's_expect_future_earnings',
                type: 'radio',
                title: {
                  label: `Will your spouse be paid for part-time or contract work after retirement?`,
                },
                stackType: 'STACKED',
                value: tempAnswers.s_expect_future_earnings,
                updateValue: (val: string) => {
                  updateAnswers({
                    ...tempAnswers,
                    s_expect_future_earnings: val,
                    ...(val === 'n'
                      ? {
                          s_future_part_time_pay:
                            SurveyDefaultAnswers.s_future_part_time_pay,
                          s_yrs_part_time_pay:
                            SurveyDefaultAnswers.s_yrs_part_time_pay,
                        }
                      : {}),
                  });
                },
                props: {
                  options: [
                    { key: 'y', label: 'Yes' },
                    { key: 'n', label: 'No' },
                  ],
                  direction: 'row',
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(answers.include_spouse === 'y' &&
        tempAnswers.s_expect_future_earnings === 'y'
          ? ([
              {
                key: 's_future_earnings_title',
                type: 'title',
                title: {
                  label: `Post-Retirement Income`,

                  size: FONT_SIZE.PX20,
                  font: FONTS.BOLD,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(answers.include_spouse === 'y' &&
        tempAnswers.s_expect_future_earnings === 'y'
          ? ([
              {
                key: 's_future_part_time_pay',
                type: 'number',
                title: {
                  label: `How much does your spouse expect to earn in future years from part-time or contract work?`,

                  helpText: {
                    key: 's_future_part_time_pay_help',
                    text: `This should be shown as a gross annual amount, before taxes and deductions. If it varies, enter an average.`,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.s_future_part_time_pay,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    s_future_part_time_pay: val,
                  });
                },
                props: {
                  suffix: `CAD`,
                  min: 0,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(answers.include_spouse === 'y' &&
        tempAnswers.s_expect_future_earnings === 'y'
          ? ([
              {
                key: 's_yrs_part_time_pay',
                type: 'number',
                title: {
                  label: `How many more years does your spouse expect to have paid employment?`,
                },
                stackType: 'STACKED',
                value: tempAnswers.s_yrs_part_time_pay,
                updateValue: (val?: number) => {
                  updateAnswers({ ...tempAnswers, s_yrs_part_time_pay: val });
                },
                props: {
                  suffix: `yrs`,
                  min: 0,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
      ],
      validation: (ans: SurveyAnswersType) => {
        const temp: ValidationReturn = {};
        if (ans.include_spouse === 'y') {
          if (
            !ans.s_pre_retire_work_years &&
            ans.s_pre_retire_work_years !== 0
          ) {
            temp.s_pre_retire_work_years = 'Please enter a value.';
          }

          if (ans.s_pre_retire_work_years !== undefined) {
            if (ans.s_pre_retire_work_years < 0) {
              temp.s_pre_retire_work_years = 'Please enter a value >= 0.';
            }
          }

          if (ans.s_pre_retire_work_years !== undefined) {
            if (ans.s_pre_retire_work_years > 50) {
              temp.s_pre_retire_work_years = 'Please enter a value <= 50.';
            }
          }
        }

        if (
          ans.include_spouse === 'y' &&
          (ans.s_pre_retire_work_years || 0) > 0
        ) {
          if (!['y', 'n'].includes(ans.s_pre_retire_self_employed)) {
            temp.s_pre_retire_self_employed =
              SURVEY_ERRORS.s_pre_retire_self_employed;
          }
        }

        if (
          ans.include_spouse === 'y' &&
          (ans.s_pre_retire_work_years || 0) > 0
        ) {
          if (
            !ans.s_pre_retire_annual_pay &&
            ans.s_pre_retire_annual_pay !== 0
          ) {
            temp.s_pre_retire_annual_pay = 'Please enter a value.';
          }

          if (ans.s_pre_retire_annual_pay !== undefined) {
            if (ans.s_pre_retire_annual_pay < 0) {
              temp.s_pre_retire_annual_pay = 'Please enter a value >= 0.';
            }
          }
        }

        if (
          isSubscribed &&
          ans.include_spouse === 'y' &&
          (ans.s_pre_retire_work_years || 0) > 1
        ) {
          if (
            !ans.s_pre_retire_full_time_incr &&
            ans.s_pre_retire_full_time_incr !== 0
          ) {
            temp.s_pre_retire_full_time_incr = 'Please enter a value.';
          }

          if (ans.s_pre_retire_full_time_incr !== undefined) {
            if (ans.s_pre_retire_full_time_incr < -10) {
              temp.s_pre_retire_full_time_incr = 'Please enter a value >= -10.';
            }
          }

          if (ans.s_pre_retire_full_time_incr !== undefined) {
            if (ans.s_pre_retire_full_time_incr > 10) {
              temp.s_pre_retire_full_time_incr = 'Please enter a value <= 10.';
            }
          }
        }

        if (
          ans.include_spouse === 'y' &&
          (ans.s_pre_retire_work_years || 0) > 0
        ) {
          if (!ans.s_pre_retire_rrsp && ans.s_pre_retire_rrsp !== 0) {
            temp.s_pre_retire_rrsp = 'Please enter a value.';
          }

          if (ans.s_pre_retire_rrsp !== undefined) {
            if (ans.s_pre_retire_rrsp < 0) {
              temp.s_pre_retire_rrsp = 'Please enter a value >= 0.';
            }
          }

          if (ans.s_pre_retire_rrsp !== undefined) {
            if (ans.s_pre_retire_rrsp > 100) {
              temp.s_pre_retire_rrsp = 'Please enter a value <= 100.';
            }
          }
        }

        if (
          isSubscribed &&
          ans.include_spouse === 'y' &&
          (ans.s_pre_retire_work_years || 0) > 0
        ) {
          if (!['y', 'n'].includes(ans.s_pre_retire_use_max_tfsa)) {
            temp.s_pre_retire_use_max_tfsa =
              SURVEY_ERRORS.s_pre_retire_use_max_tfsa;
          }
        }

        if (
          ans.include_spouse === 'y' &&
          (ans.s_pre_retire_work_years || 0) > 0 &&
          ans.s_pre_retire_use_max_tfsa === 'n'
        ) {
          if (!ans.s_pre_retire_tfsa && ans.s_pre_retire_tfsa !== 0) {
            temp.s_pre_retire_tfsa = 'Please enter a value.';
          }

          if (ans.s_pre_retire_tfsa !== undefined) {
            if (ans.s_pre_retire_tfsa < 0) {
              temp.s_pre_retire_tfsa = 'Please enter a value >= 0.';
            }
          }

          if (ans.s_pre_retire_tfsa !== undefined) {
            if (ans.s_pre_retire_tfsa > 100) {
              temp.s_pre_retire_tfsa = 'Please enter a value <= 100.';
            }
          }
        }

        if (
          ans.include_spouse === 'y' &&
          (ans.s_pre_retire_work_years || 0) > 0
        ) {
          if (!ans.s_pre_retire_you_dc && ans.s_pre_retire_you_dc !== 0) {
            temp.s_pre_retire_you_dc = 'Please enter a value.';
          }

          if (ans.s_pre_retire_you_dc !== undefined) {
            if (ans.s_pre_retire_you_dc < 0) {
              temp.s_pre_retire_you_dc = 'Please enter a value >= 0.';
            }
          }

          if (ans.s_pre_retire_you_dc !== undefined) {
            if (ans.s_pre_retire_you_dc > 100) {
              temp.s_pre_retire_you_dc = 'Please enter a value <= 100.';
            }
          }
        }

        if (
          ans.include_spouse === 'y' &&
          (ans.s_pre_retire_work_years || 0) > 0
        ) {
          if (
            !ans.s_pre_retire_employer_dc &&
            ans.s_pre_retire_employer_dc !== 0
          ) {
            temp.s_pre_retire_employer_dc = 'Please enter a value.';
          }

          if (ans.s_pre_retire_employer_dc !== undefined) {
            if (ans.s_pre_retire_employer_dc < 0) {
              temp.s_pre_retire_employer_dc = 'Please enter a value >= 0.';
            }
          }

          if (ans.s_pre_retire_employer_dc !== undefined) {
            if (ans.s_pre_retire_employer_dc > 100) {
              temp.s_pre_retire_employer_dc = 'Please enter a value <= 100.';
            }
          }
        }

        if (
          ans.include_spouse === 'y' &&
          (ans.s_pre_retire_work_years || 0) > 0
        ) {
          if (!ans.s_pre_retire_db && ans.s_pre_retire_db !== 0) {
            temp.s_pre_retire_db = 'Please enter a value.';
          }

          if (ans.s_pre_retire_db !== undefined) {
            if (ans.s_pre_retire_db < 0) {
              temp.s_pre_retire_db = 'Please enter a value >= 0.';
            }
          }

          if (ans.s_pre_retire_db !== undefined) {
            if (ans.s_pre_retire_db > 100) {
              temp.s_pre_retire_db = 'Please enter a value <= 100.';
            }
          }
        }

        if (ans.include_spouse === 'y') {
          if (!['y', 'n'].includes(ans.s_expect_future_earnings)) {
            temp.s_expect_future_earnings =
              SURVEY_ERRORS.s_expect_future_earnings;
          }
        }

        if (
          ans.include_spouse === 'y' &&
          ans.s_expect_future_earnings === 'y'
        ) {
          if (!ans.s_future_part_time_pay && ans.s_future_part_time_pay !== 0) {
            temp.s_future_part_time_pay = 'Please enter a value.';
          }

          if (ans.s_future_part_time_pay !== undefined) {
            if (ans.s_future_part_time_pay < 0) {
              temp.s_future_part_time_pay = 'Please enter a value >= 0.';
            }
          }
        }

        if (
          ans.include_spouse === 'y' &&
          ans.s_expect_future_earnings === 'y'
        ) {
          if (!ans.s_yrs_part_time_pay && ans.s_yrs_part_time_pay !== 0) {
            temp.s_yrs_part_time_pay = 'Please enter a value.';
          }

          if (ans.s_yrs_part_time_pay !== undefined) {
            if (ans.s_yrs_part_time_pay < 0) {
              temp.s_yrs_part_time_pay = 'Please enter a value >= 0.';
            }
          }
        }
        return temp;
      },
    },

    // MARK: Page 7
    {
      key: 'additional_income',
      pageType: 'standard',
      title: `Additional Income`,
      questions: (
        tempAnswers: SurveyAnswersType,
        updateAnswers: (val: SurveyAnswersType) => void,
      ) => [
        ...(isSubscribed
          ? ([
              {
                key: 'p_inv_property',
                type: 'title',
                title: {
                  label: `Investment Properties`,

                  color: !isSubscribed ? COLORS.GRAY : COLORS.MAIN,
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 'p_inv_property',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],

                  size: FONT_SIZE.PX20,
                  font: FONTS.BOLD,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed
          ? ([
              {
                key: 'p_inv_property_1',
                type: 'radio',
                title: {
                  label: `Do you own an investment property besides your principal residence?`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 'p_inv_property_1',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],
                },
                stackType: 'STACKED',
                value: tempAnswers.p_inv_property_1,
                updateValue: (val: string) => {
                  updateAnswers({
                    ...tempAnswers,
                    p_inv_property_1: val,
                    ...(val === 'n'
                      ? {
                          p_inv_property_1_mo_rent:
                            SurveyDefaultAnswers.p_inv_property_1_mo_rent,
                          p_inv_property_1_keep_yrs:
                            SurveyDefaultAnswers.p_inv_property_1_keep_yrs,
                          p_inv_property_1_exp_sale_price:
                            SurveyDefaultAnswers.p_inv_property_1_exp_sale_price,
                          p_inv_property_2:
                            SurveyDefaultAnswers.p_inv_property_2,
                          p_inv_property_2_mo_rent:
                            SurveyDefaultAnswers.p_inv_property_2_mo_rent,
                          p_inv_property_2_keep_yrs:
                            SurveyDefaultAnswers.p_inv_property_2_keep_yrs,
                          p_inv_property_2_exp_sale_price:
                            SurveyDefaultAnswers.p_inv_property_2_exp_sale_price,
                        }
                      : {}),
                  });
                },
                props: {
                  options: [
                    { key: 'y', label: 'Yes' },
                    { key: 'n', label: 'No' },
                  ],
                  direction: 'row',
                  disabled: !isSubscribed,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed && tempAnswers.p_inv_property_1 === 'y'
          ? ([
              {
                key: 'p_inv_property_1_title',
                type: 'title',
                title: {
                  label: `Investment Property 1`,

                  color: !isSubscribed ? COLORS.GRAY : COLORS.MAIN,
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 'p_inv_property_1_title',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],

                  size: FONT_SIZE.PX20,
                  font: FONTS.BOLD,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed && tempAnswers.p_inv_property_1 === 'y'
          ? ([
              {
                key: 'p_inv_property_1_mo_rent',
                type: 'number',
                title: {
                  label: `How much monthly rent or other income you are getting from your investment property, net of expenses?`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 'p_inv_property_1_mo_rent',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],

                  helpText: {
                    key: 'p_inv_property_1_mo_rent_help',
                    text: `For this purpose, expenses includes mortgage payments or other debt repayments. If you co-own the property, show only your share of rent.`,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.p_inv_property_1_mo_rent,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    p_inv_property_1_mo_rent: val,
                  });
                },
                props: {
                  disabled: !isSubscribed,

                  suffix: `CAD`,
                  min: 0,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed && tempAnswers.p_inv_property_1 === 'y'
          ? ([
              {
                key: 'p_inv_property_1_keep_yrs',
                type: 'number',
                title: {
                  label: `Number of years you expect to continue to hold the property?`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 'p_inv_property_1_keep_yrs',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],

                  helpText: {
                    key: 'p_inv_property_1_keep_yrs_help',
                    text: `It is important to estimate when you expect to sell the property.`,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.p_inv_property_1_keep_yrs,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    p_inv_property_1_keep_yrs: val,
                  });
                },
                props: {
                  disabled: !isSubscribed,

                  suffix: `yrs`,
                  min: 0,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed && tempAnswers.p_inv_property_1 === 'y'
          ? ([
              {
                key: 'p_inv_property_1_exp_sale_price',
                type: 'number',
                title: {
                  label: `Amount you expect to realize on the sale of the property, net of any mortgage or expenses and after tax?`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 'p_inv_property_1_exp_sale_price',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],

                  helpText: {
                    key: 'p_inv_property_1_exp_sale_price_help',
                    text: `For example, if sale price is $500,000, the outstanding mortgage is $200,000, sales commissions are $20,000 and income tax is estimated to be $50,000, then enter $230,000. `,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.p_inv_property_1_exp_sale_price,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    p_inv_property_1_exp_sale_price: val,
                  });
                },
                props: {
                  disabled: !isSubscribed,

                  suffix: `CAD`,
                  min: 0,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed && tempAnswers.p_inv_property_1 === 'y'
          ? ([
              {
                key: 'p_inv_property_2',
                type: 'radio',
                title: {
                  label: `Do you own a second investment property?`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 'p_inv_property_2',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],
                },
                stackType: 'STACKED',
                value: tempAnswers.p_inv_property_2,
                updateValue: (val: string) => {
                  updateAnswers({
                    ...tempAnswers,
                    p_inv_property_2: val,
                    ...(val === 'n'
                      ? {
                          p_inv_property_2_mo_rent:
                            SurveyDefaultAnswers.p_inv_property_2_mo_rent,
                          p_inv_property_2_keep_yrs:
                            SurveyDefaultAnswers.p_inv_property_2_keep_yrs,
                          p_inv_property_2_exp_sale_price:
                            SurveyDefaultAnswers.p_inv_property_2_exp_sale_price,
                        }
                      : {}),
                  });
                },
                props: {
                  options: [
                    { key: 'y', label: 'Yes' },
                    { key: 'n', label: 'No' },
                  ],
                  direction: 'row',
                  disabled: !isSubscribed,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed && tempAnswers.p_inv_property_2 === 'y'
          ? ([
              {
                key: 'p_inv_property_2_title',
                type: 'title',
                title: {
                  label: `Investment Property 2`,

                  color: !isSubscribed ? COLORS.GRAY : COLORS.MAIN,
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 'p_inv_property_2_title',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],

                  size: FONT_SIZE.PX20,
                  font: FONTS.BOLD,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed && tempAnswers.p_inv_property_2 === 'y'
          ? ([
              {
                key: 'p_inv_property_2_mo_rent',
                type: 'number',
                title: {
                  label: `Monthly rent or income you are getting from your investment property, net of expenses?`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 'p_inv_property_2_mo_rent',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],
                },
                stackType: 'STACKED',
                value: tempAnswers.p_inv_property_2_mo_rent,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    p_inv_property_2_mo_rent: val,
                  });
                },
                props: {
                  disabled: !isSubscribed,

                  suffix: `CAD`,
                  min: 0,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed && tempAnswers.p_inv_property_2 === 'y'
          ? ([
              {
                key: 'p_inv_property_2_keep_yrs',
                type: 'number',
                title: {
                  label: `Number of years you expect to continue to hold the property?`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 'p_inv_property_2_keep_yrs',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],
                },
                stackType: 'STACKED',
                value: tempAnswers.p_inv_property_2_keep_yrs,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    p_inv_property_2_keep_yrs: val,
                  });
                },
                props: {
                  disabled: !isSubscribed,

                  suffix: `yrs`,
                  min: 0,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed && tempAnswers.p_inv_property_2 === 'y'
          ? ([
              {
                key: 'p_inv_property_2_exp_sale_price',
                type: 'number',
                title: {
                  label: `Amount you expect to realize on the sale of the property, net of any mortgage or expenses and after tax?`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 'p_inv_property_2_exp_sale_price',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],
                },
                stackType: 'STACKED',
                value: tempAnswers.p_inv_property_2_exp_sale_price,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    p_inv_property_2_exp_sale_price: val,
                  });
                },
                props: {
                  disabled: !isSubscribed,

                  suffix: `CAD`,
                  min: 0,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed
          ? ([
              {
                key: 'future_windfall_spacer',
                type: 'spacer',
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed
          ? ([
              {
                key: 'future_windfall_title',
                type: 'title',
                title: {
                  label: `Future Windfall`,

                  color: !isSubscribed ? COLORS.GRAY : COLORS.MAIN,
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 'future_windfall_title',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],

                  size: FONT_SIZE.PX20,
                  font: FONTS.BOLD,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed
          ? ([
              {
                key: 'p_future_windfall_net_tax_expenses',
                type: 'number',
                title: {
                  label: `How much future 'windfall' do you expect, net of tax and expenses?`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 'p_future_windfall_net_tax_expenses',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],

                  helpText: {
                    key: 'p_future_windfall_net_tax_expenses_help',
                    text: `This might be an inheritance or net proceeds from downsizing your house. Do not include the sale of an investment property.`,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.p_future_windfall_net_tax_expenses,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    p_future_windfall_net_tax_expenses: val,
                    ...((val || 0) === 0
                      ? {
                          p_years_until_windfall:
                            SurveyDefaultAnswers.p_years_until_windfall,
                        }
                      : {}),
                  });
                },
                props: {
                  disabled: !isSubscribed,

                  suffix: `CAD`,
                  min: 0,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed &&
        (tempAnswers.p_future_windfall_net_tax_expenses || 0) > 0
          ? ([
              {
                key: 'p_years_until_windfall',
                type: 'number',
                title: {
                  label: `How many years before you receive the 'windfall'?`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 'p_years_until_windfall',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],
                },
                stackType: 'STACKED',
                value: tempAnswers.p_years_until_windfall,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    p_years_until_windfall: val,
                  });
                },
                props: {
                  disabled: !isSubscribed,

                  suffix: `yrs`,
                  min: 0,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed && answers.include_spouse === 'y'
          ? ([
              {
                key: 's_future_windfall_net_tax_expenses',
                type: 'number',
                title: {
                  label: `How much future 'windfall' does your spouse expect, net of tax and expenses?`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 's_future_windfall_net_tax_expenses',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],

                  helpText: {
                    key: 's_future_windfall_net_tax_expenses_help',
                    text: `Examples, the net proceeds from downsizing or an inheritance. Do not include investment properties here. Do not double-count family assets.`,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.s_future_windfall_net_tax_expenses,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    s_future_windfall_net_tax_expenses: val,
                    ...((val || 0) === 0
                      ? {
                          s_years_until_windfall:
                            SurveyDefaultAnswers.s_years_until_windfall,
                        }
                      : {}),
                  });
                },
                props: {
                  disabled: !isSubscribed,

                  suffix: `CAD`,
                  min: 0,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed &&
        answers.include_spouse === 'y' &&
        (tempAnswers.s_future_windfall_net_tax_expenses || 0) > 0
          ? ([
              {
                key: 's_years_until_windfall',
                type: 'number',
                title: {
                  label: `How many years before your spouse receives the 'windfall'?`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 's_years_until_windfall',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],
                },
                stackType: 'STACKED',
                value: tempAnswers.s_years_until_windfall,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    s_years_until_windfall: val,
                  });
                },
                props: {
                  disabled: !isSubscribed,

                  suffix: `yrs`,
                  min: 0,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed
          ? ([
              {
                key: 'annuity_spacer',
                type: 'spacer',
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed
          ? ([
              {
                key: 'annuity_title',
                type: 'title',
                title: {
                  label: `Annuities`,

                  color: !isSubscribed ? COLORS.GRAY : COLORS.MAIN,
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 'annuity_title',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],

                  size: FONT_SIZE.PX20,
                  font: FONTS.BOLD,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed
          ? ([
              {
                key: 'p_old_annuity_amount',
                type: 'number',
                title: {
                  label: `Existing annuity payable to you`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 'p_old_annuity_amount',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],

                  helpText: {
                    key: 'p_old_annuity_amount_help',
                    text: `Annual payments for an annuity that you already purchased.`,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.p_old_annuity_amount,
                updateValue: (val?: number) => {
                  updateAnswers({ ...tempAnswers, p_old_annuity_amount: val });
                },
                props: {
                  disabled: !isSubscribed,

                  suffix: `CAD`,
                  min: 0,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed &&
        (tempAnswers.p_old_annuity_amount || 0) > 0 &&
        ((tempAnswers.p_old_annuity_amount || 0) < 1000 ||
          (tempAnswers.p_old_annuity_amount || 0) > 99000)
          ? ([
              {
                key: 'p_old_annuity_warning',
                type: 'rawText',
                value: [
                  {
                    text: `Note this is supposed to be an annual annuity amount`,
                    color: COLORS.DARK_RED,
                  },
                ],
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed && answers.include_spouse === 'y'
          ? ([
              {
                key: 's_old_annuity_amount',
                type: 'number',
                title: {
                  label: `Existing annuity payable to your spouse`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 's_old_annuity_amount',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],

                  helpText: {
                    key: 's_old_annuity_amount_help',
                    text: `Annual payments for an annuity that your spouse already purchased.`,
                  },
                },
                stackType: 'STACKED',
                value: tempAnswers.s_old_annuity_amount,
                updateValue: (val?: number) => {
                  updateAnswers({ ...tempAnswers, s_old_annuity_amount: val });
                },
                props: {
                  disabled: !isSubscribed,

                  suffix: `CAD`,
                  min: 0,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed &&
        answers.include_spouse === 'y' &&
        (tempAnswers.s_old_annuity_amount || 0) > 0 &&
        ((tempAnswers.s_old_annuity_amount || 0) < 1000 ||
          (tempAnswers.s_old_annuity_amount || 0) > 99000)
          ? ([
              {
                key: 's_old_annuity_warning',
                type: 'rawText',
                value: [
                  {
                    text: `Note this is supposed to be an annual annuity amount`,
                    color: COLORS.DARK_RED,
                  },
                ],
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed
          ? ([
              {
                key: `p_purchased_annuity_description`,
                type: 'rawText',
                value: [
                  {
                    text: `Enter a dollar amount. This annuity would be purchased at 65 if you are younger.`,
                    color: COLORS.BLACK,
                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.NORMAL,
                  },
                ],
                title: {
                  label: `Amount of money you wish to use to purchase a new annuity.`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 'p_purchased_annuity',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],

                  helpText: {
                    key: 'p_purchased_annuity_help',
                    text: `The money would come from your DC plan or LIF if you have one, otherwise from your RRSP or RRIF.`,
                  },
                },
              },
              {
                key: `p_purchased_annuity_counter`,
                type: 'rawText',
                value: [
                  {
                    text: `Basic PERC: `,

                    color: !isSubscribed ? COLORS.GRAY : COLORS.BLACK,
                    frontButtons: isSubscribed
                      ? []
                      : [
                          {
                            key: 'p_purchased_annuity',
                            text: 'Unlock',
                            fcn: openSubscriptionPage,
                            style: 'TRANSPARENT',
                            custom: {
                              fontSize: FONT_SIZE.PX14,
                              fontFamily: FONTS.BOLD,
                              color: COLORS.MAIN,
                              hoverColor: COLORS.BLACK,
                            },
                          },
                        ],

                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.BOLD,
                  },
                  {
                    text: `20% of starting assets (${formatByType({
                      type: 'round_dollars',
                      val: taxableAssetTotal.p * 0.2,
                    })})`,

                    color: !isSubscribed ? COLORS.GRAY : COLORS.BLACK,
                    frontButtons: isSubscribed
                      ? []
                      : [
                          {
                            key: 'p_purchased_annuity',
                            text: 'Unlock',
                            fcn: openSubscriptionPage,
                            style: 'TRANSPARENT',
                            custom: {
                              fontSize: FONT_SIZE.PX14,
                              fontFamily: FONTS.BOLD,
                              color: COLORS.MAIN,
                              hoverColor: COLORS.BLACK,
                            },
                          },
                        ],

                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.NORMAL,
                  },
                  {
                    text: ` | `,
                    color: COLORS.TABLE_GRAY,
                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.NORMAL,
                  },
                  {
                    text: `Your choice: `,

                    color: !isSubscribed ? COLORS.GRAY : COLORS.BLACK,
                    frontButtons: isSubscribed
                      ? []
                      : [
                          {
                            key: 'p_purchased_annuity',
                            text: 'Unlock',
                            fcn: openSubscriptionPage,
                            style: 'TRANSPARENT',
                            custom: {
                              fontSize: FONT_SIZE.PX14,
                              fontFamily: FONTS.BOLD,
                              color: COLORS.MAIN,
                              hoverColor: COLORS.BLACK,
                            },
                          },
                        ],

                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.BOLD,
                  },
                  {
                    text: (() => {
                      const a = tempAnswers.p_purchased_annuity;
                      return formatByType({
                        type: 'round_dollars',
                        val: a || 0,
                      });
                    })(),

                    color: !isSubscribed ? COLORS.GRAY : COLORS.BLACK,
                    frontButtons: isSubscribed
                      ? []
                      : [
                          {
                            key: 'p_purchased_annuity',
                            text: 'Unlock',
                            fcn: openSubscriptionPage,
                            style: 'TRANSPARENT',
                            custom: {
                              fontSize: FONT_SIZE.PX14,
                              fontFamily: FONTS.BOLD,
                              color: COLORS.MAIN,
                              hoverColor: COLORS.BLACK,
                            },
                          },
                        ],

                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.NORMAL,
                  },
                ],
                textAlign: 'center',
                hideBottomPadding: true,
              },

              {
                key: `p_purchased_annuity_number`,
                type: 'number',
                stackType: 'STACKED',
                value: tempAnswers.p_purchased_annuity,
                updateValue: (val?: number) => {
                  updateAnswers({ ...tempAnswers, p_purchased_annuity: val });
                },
                props: {
                  disabled: !isSubscribed,
                  min: 0,
                  max: taxableAssetTotal.p * 0.5,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed && answers.include_spouse === 'y'
          ? ([
              {
                key: `s_purchased_annuity_description`,
                type: 'rawText',
                value: [
                  {
                    text: `Enter a dollar amount. This annuity would be purchased at 65 if your spouse is younger.`,
                    color: COLORS.BLACK,
                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.NORMAL,
                  },
                ],
                title: {
                  label: `Amount of money your spouse wishes to use to purchase a new annuity.`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 's_purchased_annuity',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],

                  helpText: {
                    key: 's_purchased_annuity_help',
                    text: `The money would come from your spouse's DC plan or LIF if they have one, otherwise from their RRSP or RRIF.`,
                  },
                },
              },
              {
                key: `s_purchased_annuity_counter`,
                type: 'rawText',
                value: [
                  {
                    text: `Basic PERC: `,

                    color: !isSubscribed ? COLORS.GRAY : COLORS.BLACK,
                    frontButtons: isSubscribed
                      ? []
                      : [
                          {
                            key: 's_purchased_annuity',
                            text: 'Unlock',
                            fcn: openSubscriptionPage,
                            style: 'TRANSPARENT',
                            custom: {
                              fontSize: FONT_SIZE.PX14,
                              fontFamily: FONTS.BOLD,
                              color: COLORS.MAIN,
                              hoverColor: COLORS.BLACK,
                            },
                          },
                        ],

                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.BOLD,
                  },
                  {
                    text: `20% of starting assets (${formatByType({
                      type: 'round_dollars',
                      val: taxableAssetTotal.s * 0.2,
                    })})`,

                    color: !isSubscribed ? COLORS.GRAY : COLORS.BLACK,
                    frontButtons: isSubscribed
                      ? []
                      : [
                          {
                            key: 's_purchased_annuity',
                            text: 'Unlock',
                            fcn: openSubscriptionPage,
                            style: 'TRANSPARENT',
                            custom: {
                              fontSize: FONT_SIZE.PX14,
                              fontFamily: FONTS.BOLD,
                              color: COLORS.MAIN,
                              hoverColor: COLORS.BLACK,
                            },
                          },
                        ],

                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.NORMAL,
                  },
                  {
                    text: ` | `,
                    color: COLORS.TABLE_GRAY,
                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.NORMAL,
                  },
                  {
                    text: `Your choice: `,

                    color: !isSubscribed ? COLORS.GRAY : COLORS.BLACK,
                    frontButtons: isSubscribed
                      ? []
                      : [
                          {
                            key: 's_purchased_annuity',
                            text: 'Unlock',
                            fcn: openSubscriptionPage,
                            style: 'TRANSPARENT',
                            custom: {
                              fontSize: FONT_SIZE.PX14,
                              fontFamily: FONTS.BOLD,
                              color: COLORS.MAIN,
                              hoverColor: COLORS.BLACK,
                            },
                          },
                        ],

                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.BOLD,
                  },
                  {
                    text: (() => {
                      const a = tempAnswers.s_purchased_annuity;
                      return formatByType({
                        type: 'round_dollars',
                        val: a || 0,
                      });
                    })(),

                    color: !isSubscribed ? COLORS.GRAY : COLORS.BLACK,
                    frontButtons: isSubscribed
                      ? []
                      : [
                          {
                            key: 's_purchased_annuity',
                            text: 'Unlock',
                            fcn: openSubscriptionPage,
                            style: 'TRANSPARENT',
                            custom: {
                              fontSize: FONT_SIZE.PX14,
                              fontFamily: FONTS.BOLD,
                              color: COLORS.MAIN,
                              hoverColor: COLORS.BLACK,
                            },
                          },
                        ],

                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.NORMAL,
                  },
                ],
                textAlign: 'center',
                hideBottomPadding: true,
              },

              {
                key: `s_purchased_annuity_number`,
                type: 'number',
                stackType: 'STACKED',
                value: tempAnswers.s_purchased_annuity,
                updateValue: (val?: number) => {
                  updateAnswers({ ...tempAnswers, s_purchased_annuity: val });
                },
                props: {
                  disabled: !isSubscribed,
                  min: 0,
                  max: taxableAssetTotal.s * 0.5,
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
      ],
      validation: (ans: SurveyAnswersType) => {
        const temp: ValidationReturn = {};
        if (isSubscribed) {
          if (!['y', 'n'].includes(ans.p_inv_property_1)) {
            temp.p_inv_property_1 = SURVEY_ERRORS.p_inv_property_1;
          }
        }

        if (isSubscribed && ans.p_inv_property_1 === 'y') {
          if (
            !ans.p_inv_property_1_mo_rent &&
            ans.p_inv_property_1_mo_rent !== 0
          ) {
            temp.p_inv_property_1_mo_rent = 'Please enter a value.';
          }

          if (ans.p_inv_property_1_mo_rent !== undefined) {
            if (ans.p_inv_property_1_mo_rent < 0) {
              temp.p_inv_property_1_mo_rent = 'Please enter a value >= 0.';
            }
          }
        }

        if (isSubscribed && ans.p_inv_property_1 === 'y') {
          if (
            !ans.p_inv_property_1_keep_yrs &&
            ans.p_inv_property_1_keep_yrs !== 0
          ) {
            temp.p_inv_property_1_keep_yrs = 'Please enter a value.';
          }

          if (ans.p_inv_property_1_keep_yrs !== undefined) {
            if (ans.p_inv_property_1_keep_yrs < 0) {
              temp.p_inv_property_1_keep_yrs = 'Please enter a value >= 0.';
            }
          }
        }

        if (isSubscribed && ans.p_inv_property_1 === 'y') {
          if (
            !ans.p_inv_property_1_exp_sale_price &&
            ans.p_inv_property_1_exp_sale_price !== 0
          ) {
            temp.p_inv_property_1_exp_sale_price = 'Please enter a value.';
          }

          if (ans.p_inv_property_1_exp_sale_price !== undefined) {
            if (ans.p_inv_property_1_exp_sale_price < 0) {
              temp.p_inv_property_1_exp_sale_price =
                'Please enter a value >= 0.';
            }
          }
        }

        if (isSubscribed && ans.p_inv_property_1 === 'y') {
          if (!['y', 'n'].includes(ans.p_inv_property_2)) {
            temp.p_inv_property_2 = SURVEY_ERRORS.p_inv_property_2;
          }
        }

        if (isSubscribed && ans.p_inv_property_2 === 'y') {
          if (
            !ans.p_inv_property_2_mo_rent &&
            ans.p_inv_property_2_mo_rent !== 0
          ) {
            temp.p_inv_property_2_mo_rent = 'Please enter a value.';
          }

          if (ans.p_inv_property_2_mo_rent !== undefined) {
            if (ans.p_inv_property_2_mo_rent < 0) {
              temp.p_inv_property_2_mo_rent = 'Please enter a value >= 0.';
            }
          }
        }

        if (isSubscribed && ans.p_inv_property_2 === 'y') {
          if (
            !ans.p_inv_property_2_keep_yrs &&
            ans.p_inv_property_2_keep_yrs !== 0
          ) {
            temp.p_inv_property_2_keep_yrs = 'Please enter a value.';
          }

          if (ans.p_inv_property_2_keep_yrs !== undefined) {
            if (ans.p_inv_property_2_keep_yrs < 0) {
              temp.p_inv_property_2_keep_yrs = 'Please enter a value >= 0.';
            }
          }
        }

        if (isSubscribed && ans.p_inv_property_2 === 'y') {
          if (
            !ans.p_inv_property_2_exp_sale_price &&
            ans.p_inv_property_2_exp_sale_price !== 0
          ) {
            temp.p_inv_property_2_exp_sale_price = 'Please enter a value.';
          }

          if (ans.p_inv_property_2_exp_sale_price !== undefined) {
            if (ans.p_inv_property_2_exp_sale_price < 0) {
              temp.p_inv_property_2_exp_sale_price =
                'Please enter a value >= 0.';
            }
          }
        }

        if (isSubscribed) {
          if (
            !ans.p_future_windfall_net_tax_expenses &&
            ans.p_future_windfall_net_tax_expenses !== 0
          ) {
            temp.p_future_windfall_net_tax_expenses = 'Please enter a value.';
          }

          if (ans.p_future_windfall_net_tax_expenses !== undefined) {
            if (ans.p_future_windfall_net_tax_expenses < 0) {
              temp.p_future_windfall_net_tax_expenses =
                'Please enter a value >= 0.';
            }
          }
        }

        if (isSubscribed && (ans.p_future_windfall_net_tax_expenses || 0) > 0) {
          if (!ans.p_years_until_windfall && ans.p_years_until_windfall !== 0) {
            temp.p_years_until_windfall = 'Please enter a value.';
          }

          if (ans.p_years_until_windfall !== undefined) {
            if (ans.p_years_until_windfall < 0) {
              temp.p_years_until_windfall = 'Please enter a value >= 0.';
            }
          }
        }

        if (isSubscribed && ans.include_spouse === 'y') {
          if (
            !ans.s_future_windfall_net_tax_expenses &&
            ans.s_future_windfall_net_tax_expenses !== 0
          ) {
            temp.s_future_windfall_net_tax_expenses = 'Please enter a value.';
          }

          if (ans.s_future_windfall_net_tax_expenses !== undefined) {
            if (ans.s_future_windfall_net_tax_expenses < 0) {
              temp.s_future_windfall_net_tax_expenses =
                'Please enter a value >= 0.';
            }
          }
        }

        if (
          isSubscribed &&
          ans.include_spouse === 'y' &&
          (ans.s_future_windfall_net_tax_expenses || 0) > 0
        ) {
          if (!ans.s_years_until_windfall && ans.s_years_until_windfall !== 0) {
            temp.s_years_until_windfall = 'Please enter a value.';
          }

          if (ans.s_years_until_windfall !== undefined) {
            if (ans.s_years_until_windfall < 0) {
              temp.s_years_until_windfall = 'Please enter a value >= 0.';
            }
          }
        }

        if (isSubscribed) {
          if (!ans.p_old_annuity_amount && ans.p_old_annuity_amount !== 0) {
            temp.p_old_annuity_amount = 'Please enter a value.';
          }

          if (ans.p_old_annuity_amount !== undefined) {
            if (ans.p_old_annuity_amount < 0) {
              temp.p_old_annuity_amount = 'Please enter a value >= 0.';
            }
          }
        }

        if (isSubscribed && ans.include_spouse === 'y') {
          if (!ans.s_old_annuity_amount && ans.s_old_annuity_amount !== 0) {
            temp.s_old_annuity_amount = 'Please enter a value.';
          }

          if (ans.s_old_annuity_amount !== undefined) {
            if (ans.s_old_annuity_amount < 0) {
              temp.s_old_annuity_amount = 'Please enter a value >= 0.';
            }
          }
        }

        if (isSubscribed) {
          if (!ans.p_purchased_annuity && ans.p_purchased_annuity !== 0) {
            temp.p_purchased_annuity_number = 'Please enter a value.';
          }

          if (ans.p_purchased_annuity !== undefined) {
            if (ans.p_purchased_annuity < 0) {
              temp.p_purchased_annuity_number = `Please enter a value >= ${0}.`;
            }
          }

          if (ans.p_purchased_annuity !== undefined) {
            if (ans.p_purchased_annuity > taxableAssetTotal.p * 0.5) {
              temp.p_purchased_annuity_number = `Please enter a value <= ${
                taxableAssetTotal.p * 0.5
              }.`;
            }
          }
        }

        if (isSubscribed && ans.include_spouse === 'y') {
          if (!ans.s_purchased_annuity && ans.s_purchased_annuity !== 0) {
            temp.s_purchased_annuity_number = 'Please enter a value.';
          }

          if (ans.s_purchased_annuity !== undefined) {
            if (ans.s_purchased_annuity < 0) {
              temp.s_purchased_annuity_number = `Please enter a value >= ${0}.`;
            }
          }

          if (ans.s_purchased_annuity !== undefined) {
            if (ans.s_purchased_annuity > taxableAssetTotal.s * 0.5) {
              temp.s_purchased_annuity_number = `Please enter a value <= ${
                taxableAssetTotal.s * 0.5
              }.`;
            }
          }
        }
        return temp;
      },
    },

    // MARK: Page 8
    {
      key: 'custom_settings',
      pageType: 'standard',
      title: `Custom Settings`,
      questions: (
        tempAnswers: SurveyAnswersType,
        updateAnswers: (val: SurveyAnswersType) => void,
      ) => [
        ...(isSubscribed
          ? ([
              {
                key: `annual_investment_fees_description`,
                type: 'rawText',
                value: [
                  {
                    text: `Enter your actual annual fees. Take an average over all assets excluding investment properties.`,
                    color: COLORS.BLACK,
                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.NORMAL,
                  },
                ],
                title: {
                  label: `Investment Fees (annual)`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 'annual_investment_fees',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],

                  helpText: {
                    key: 'annual_investment_fees_help',
                    text: `Actual Fees`,
                  },
                },
              },
              {
                key: `annual_investment_fees_counter`,
                type: 'rawText',
                value: [
                  {
                    text: `Basic PERC: `,

                    color: !isSubscribed ? COLORS.GRAY : COLORS.BLACK,
                    frontButtons: isSubscribed
                      ? []
                      : [
                          {
                            key: 'annual_investment_fees',
                            text: 'Unlock',
                            fcn: openSubscriptionPage,
                            style: 'TRANSPARENT',
                            custom: {
                              fontSize: FONT_SIZE.PX14,
                              fontFamily: FONTS.BOLD,
                              color: COLORS.MAIN,
                              hoverColor: COLORS.BLACK,
                            },
                          },
                        ],

                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.BOLD,
                  },
                  {
                    text: `0.60%`,

                    color: !isSubscribed ? COLORS.GRAY : COLORS.BLACK,
                    frontButtons: isSubscribed
                      ? []
                      : [
                          {
                            key: 'annual_investment_fees',
                            text: 'Unlock',
                            fcn: openSubscriptionPage,
                            style: 'TRANSPARENT',
                            custom: {
                              fontSize: FONT_SIZE.PX14,
                              fontFamily: FONTS.BOLD,
                              color: COLORS.MAIN,
                              hoverColor: COLORS.BLACK,
                            },
                          },
                        ],

                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.NORMAL,
                  },
                  {
                    text: ` | `,
                    color: COLORS.TABLE_GRAY,
                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.NORMAL,
                  },
                  {
                    text: `Your choice: `,

                    color: !isSubscribed ? COLORS.GRAY : COLORS.BLACK,
                    frontButtons: isSubscribed
                      ? []
                      : [
                          {
                            key: 'annual_investment_fees',
                            text: 'Unlock',
                            fcn: openSubscriptionPage,
                            style: 'TRANSPARENT',
                            custom: {
                              fontSize: FONT_SIZE.PX14,
                              fontFamily: FONTS.BOLD,
                              color: COLORS.MAIN,
                              hoverColor: COLORS.BLACK,
                            },
                          },
                        ],

                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.BOLD,
                  },
                  {
                    text: (() => {
                      const a = tempAnswers.annual_investment_fees;
                      return `${a}%`;
                    })(),

                    color: !isSubscribed ? COLORS.GRAY : COLORS.BLACK,
                    frontButtons: isSubscribed
                      ? []
                      : [
                          {
                            key: 'annual_investment_fees',
                            text: 'Unlock',
                            fcn: openSubscriptionPage,
                            style: 'TRANSPARENT',
                            custom: {
                              fontSize: FONT_SIZE.PX14,
                              fontFamily: FONTS.BOLD,
                              color: COLORS.MAIN,
                              hoverColor: COLORS.BLACK,
                            },
                          },
                        ],

                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.NORMAL,
                  },
                ],
                textAlign: 'center',
                hideBottomPadding: true,
              },

              {
                key: `annual_investment_fees_slider`,
                type: 'slider',
                value: tempAnswers.annual_investment_fees,
                updateValue: (val: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    [`annual_investment_fees`]: val,
                  });
                },
                props: {
                  min: 0,
                  max: 4,
                  step: 0.1,
                  trackColor: COLORS.MAIN,
                  railColor: COLORS.SILVER,
                  height: 50,
                  disabled: !isSubscribed,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed
          ? ([
              {
                key: `future_investment_fees_description`,
                type: 'rawText',
                value: [
                  {
                    text: `Enter the annual investment return you wish to use; it must be between 2% and 7%.`,
                    color: COLORS.BLACK,
                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.NORMAL,
                  },
                ],
                title: {
                  label: `Future investment returns (before fees)`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 'future_investment_fees',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],
                },
              },
              {
                key: `future_investment_fees_counter`,
                type: 'rawText',
                value: [
                  {
                    text: `Basic PERC: `,

                    color: !isSubscribed ? COLORS.GRAY : COLORS.BLACK,
                    frontButtons: isSubscribed
                      ? []
                      : [
                          {
                            key: 'future_investment_fees',
                            text: 'Unlock',
                            fcn: openSubscriptionPage,
                            style: 'TRANSPARENT',
                            custom: {
                              fontSize: FONT_SIZE.PX14,
                              fontFamily: FONTS.BOLD,
                              color: COLORS.MAIN,
                              hoverColor: COLORS.BLACK,
                            },
                          },
                        ],

                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.BOLD,
                  },
                  {
                    text: `3.5%/yr`,

                    color: !isSubscribed ? COLORS.GRAY : COLORS.BLACK,
                    frontButtons: isSubscribed
                      ? []
                      : [
                          {
                            key: 'future_investment_fees',
                            text: 'Unlock',
                            fcn: openSubscriptionPage,
                            style: 'TRANSPARENT',
                            custom: {
                              fontSize: FONT_SIZE.PX14,
                              fontFamily: FONTS.BOLD,
                              color: COLORS.MAIN,
                              hoverColor: COLORS.BLACK,
                            },
                          },
                        ],

                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.NORMAL,
                  },
                  {
                    text: ` | `,
                    color: COLORS.TABLE_GRAY,
                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.NORMAL,
                  },
                  {
                    text: `Your choice: `,

                    color: !isSubscribed ? COLORS.GRAY : COLORS.BLACK,
                    frontButtons: isSubscribed
                      ? []
                      : [
                          {
                            key: 'future_investment_fees',
                            text: 'Unlock',
                            fcn: openSubscriptionPage,
                            style: 'TRANSPARENT',
                            custom: {
                              fontSize: FONT_SIZE.PX14,
                              fontFamily: FONTS.BOLD,
                              color: COLORS.MAIN,
                              hoverColor: COLORS.BLACK,
                            },
                          },
                        ],

                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.BOLD,
                  },
                  {
                    text: (() => {
                      const a = tempAnswers.future_investment_fees;
                      return `${a}%/yr`;
                    })(),

                    color: !isSubscribed ? COLORS.GRAY : COLORS.BLACK,
                    frontButtons: isSubscribed
                      ? []
                      : [
                          {
                            key: 'future_investment_fees',
                            text: 'Unlock',
                            fcn: openSubscriptionPage,
                            style: 'TRANSPARENT',
                            custom: {
                              fontSize: FONT_SIZE.PX14,
                              fontFamily: FONTS.BOLD,
                              color: COLORS.MAIN,
                              hoverColor: COLORS.BLACK,
                            },
                          },
                        ],

                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.NORMAL,
                  },
                ],
                textAlign: 'center',
                hideBottomPadding: true,
              },

              {
                key: `future_investment_fees_slider`,
                type: 'slider',
                value: tempAnswers.future_investment_fees,
                updateValue: (val: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    [`future_investment_fees`]: val,
                  });
                },
                props: {
                  min: 2,
                  max: 7,
                  step: 0.1,
                  trackColor: COLORS.MAIN,
                  railColor: COLORS.SILVER,
                  height: 50,
                  disabled: !isSubscribed,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed
          ? ([
              {
                key: `end_age_description`,
                type: 'rawText',
                value: [
                  {
                    text: `Pick an age between 88 and 100. This is based on the age of the younger spouse, if you have one.`,
                    color: COLORS.BLACK,
                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.NORMAL,
                  },
                ],
                title: {
                  label: `"End age" (Age that assets must last until)`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 'end_age',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],
                },
              },
              {
                key: `end_age_counter`,
                type: 'rawText',
                value: [
                  {
                    text: `Basic PERC: `,

                    color: !isSubscribed ? COLORS.GRAY : COLORS.BLACK,
                    frontButtons: isSubscribed
                      ? []
                      : [
                          {
                            key: 'end_age',
                            text: 'Unlock',
                            fcn: openSubscriptionPage,
                            style: 'TRANSPARENT',
                            custom: {
                              fontSize: FONT_SIZE.PX14,
                              fontFamily: FONTS.BOLD,
                              color: COLORS.MAIN,
                              hoverColor: COLORS.BLACK,
                            },
                          },
                        ],

                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.BOLD,
                  },
                  {
                    text: `Age 93`,

                    color: !isSubscribed ? COLORS.GRAY : COLORS.BLACK,
                    frontButtons: isSubscribed
                      ? []
                      : [
                          {
                            key: 'end_age',
                            text: 'Unlock',
                            fcn: openSubscriptionPage,
                            style: 'TRANSPARENT',
                            custom: {
                              fontSize: FONT_SIZE.PX14,
                              fontFamily: FONTS.BOLD,
                              color: COLORS.MAIN,
                              hoverColor: COLORS.BLACK,
                            },
                          },
                        ],

                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.NORMAL,
                  },
                  {
                    text: ` | `,
                    color: COLORS.TABLE_GRAY,
                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.NORMAL,
                  },
                  {
                    text: `Your choice: `,

                    color: !isSubscribed ? COLORS.GRAY : COLORS.BLACK,
                    frontButtons: isSubscribed
                      ? []
                      : [
                          {
                            key: 'end_age',
                            text: 'Unlock',
                            fcn: openSubscriptionPage,
                            style: 'TRANSPARENT',
                            custom: {
                              fontSize: FONT_SIZE.PX14,
                              fontFamily: FONTS.BOLD,
                              color: COLORS.MAIN,
                              hoverColor: COLORS.BLACK,
                            },
                          },
                        ],

                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.BOLD,
                  },
                  {
                    text: (() => {
                      const a = tempAnswers.end_age;
                      return `Age ${a}`;
                    })(),

                    color: !isSubscribed ? COLORS.GRAY : COLORS.BLACK,
                    frontButtons: isSubscribed
                      ? []
                      : [
                          {
                            key: 'end_age',
                            text: 'Unlock',
                            fcn: openSubscriptionPage,
                            style: 'TRANSPARENT',
                            custom: {
                              fontSize: FONT_SIZE.PX14,
                              fontFamily: FONTS.BOLD,
                              color: COLORS.MAIN,
                              hoverColor: COLORS.BLACK,
                            },
                          },
                        ],

                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.NORMAL,
                  },
                ],
                textAlign: 'center',
                hideBottomPadding: true,
              },

              {
                key: `end_age_slider`,
                type: 'slider',
                value: tempAnswers.end_age,
                updateValue: (val: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    [`end_age`]: val,
                  });
                },
                props: {
                  min: 88,
                  max: 100,
                  step: 1,
                  trackColor: COLORS.MAIN,
                  railColor: COLORS.SILVER,
                  height: 50,
                  disabled: !isSubscribed,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
        ...(isSubscribed
          ? ([
              {
                key: `end_age_remaining_assets_description`,
                type: 'rawText',
                value: [
                  {
                    text: `Enter a dollar amount between $5000 and half of your starting assets.`,
                    color: COLORS.BLACK,
                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.NORMAL,
                  },
                ],
                title: {
                  label: `Assets that you want to have remaining at the "end age"`,

                  color: !isSubscribed ? COLORS.GRAY : '',
                  frontButtons: isSubscribed
                    ? []
                    : [
                        {
                          key: 'end_age_remaining_assets',
                          text: 'Unlock',
                          fcn: openSubscriptionPage,
                          style: 'TRANSPARENT',
                          custom: {
                            fontSize: FONT_SIZE.PX14,
                            fontFamily: FONTS.BOLD,
                            color: COLORS.MAIN,
                            hoverColor: COLORS.BLACK,
                          },
                        },
                      ],
                },
              },
              {
                key: `end_age_remaining_assets_counter`,
                type: 'rawText',
                value: [
                  {
                    text: `Basic PERC: `,

                    color: !isSubscribed ? COLORS.GRAY : COLORS.BLACK,
                    frontButtons: isSubscribed
                      ? []
                      : [
                          {
                            key: 'end_age_remaining_assets',
                            text: 'Unlock',
                            fcn: openSubscriptionPage,
                            style: 'TRANSPARENT',
                            custom: {
                              fontSize: FONT_SIZE.PX14,
                              fontFamily: FONTS.BOLD,
                              color: COLORS.MAIN,
                              hoverColor: COLORS.BLACK,
                            },
                          },
                        ],

                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.BOLD,
                  },
                  {
                    text: `10% of starting assets (${formatByType({
                      type: 'round_dollars',
                      val:
                        (taxableAssetTotal.p +
                          taxableAssetTotal.s +
                          ntsTotal.p +
                          ntsTotal.s) *
                        0.1,
                    })})`,

                    color: !isSubscribed ? COLORS.GRAY : COLORS.BLACK,
                    frontButtons: isSubscribed
                      ? []
                      : [
                          {
                            key: 'end_age_remaining_assets',
                            text: 'Unlock',
                            fcn: openSubscriptionPage,
                            style: 'TRANSPARENT',
                            custom: {
                              fontSize: FONT_SIZE.PX14,
                              fontFamily: FONTS.BOLD,
                              color: COLORS.MAIN,
                              hoverColor: COLORS.BLACK,
                            },
                          },
                        ],

                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.NORMAL,
                  },
                  {
                    text: ` | `,
                    color: COLORS.TABLE_GRAY,
                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.NORMAL,
                  },
                  {
                    text: `Your choice: `,

                    color: !isSubscribed ? COLORS.GRAY : COLORS.BLACK,
                    frontButtons: isSubscribed
                      ? []
                      : [
                          {
                            key: 'end_age_remaining_assets',
                            text: 'Unlock',
                            fcn: openSubscriptionPage,
                            style: 'TRANSPARENT',
                            custom: {
                              fontSize: FONT_SIZE.PX14,
                              fontFamily: FONTS.BOLD,
                              color: COLORS.MAIN,
                              hoverColor: COLORS.BLACK,
                            },
                          },
                        ],

                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.BOLD,
                  },
                  {
                    text: (() => {
                      const a = tempAnswers.end_age_remaining_assets;
                      return formatByType({
                        type: 'round_dollars',
                        val: a || 0,
                      });
                    })(),

                    color: !isSubscribed ? COLORS.GRAY : COLORS.BLACK,
                    frontButtons: isSubscribed
                      ? []
                      : [
                          {
                            key: 'end_age_remaining_assets',
                            text: 'Unlock',
                            fcn: openSubscriptionPage,
                            style: 'TRANSPARENT',
                            custom: {
                              fontSize: FONT_SIZE.PX14,
                              fontFamily: FONTS.BOLD,
                              color: COLORS.MAIN,
                              hoverColor: COLORS.BLACK,
                            },
                          },
                        ],

                    fontSize: FONT_SIZE.PX14,
                    fontFamily: FONTS.NORMAL,
                  },
                ],
                textAlign: 'center',
                hideBottomPadding: true,
              },

              {
                key: `end_age_remaining_assets_number`,
                type: 'number',
                stackType: 'STACKED',
                value: tempAnswers.end_age_remaining_assets,
                updateValue: (val?: number) => {
                  updateAnswers({
                    ...tempAnswers,
                    end_age_remaining_assets: val,
                  });
                },
                props: {
                  disabled: !isSubscribed,
                  min: 5000,
                  max: Math.max(
                    (taxableAssetTotal.p +
                      taxableAssetTotal.s +
                      ntsTotal.p +
                      ntsTotal.s) *
                      0.5,
                    5000,
                  ),
                  decimalScale: 0,
                },
              },
            ] as Array<FormOptionType>)
          : ([] as Array<FormOptionType>)),
      ],
      validation: (ans: SurveyAnswersType) => {
        const temp: ValidationReturn = {};
        if (isSubscribed) {
          if (
            !ans.end_age_remaining_assets &&
            ans.end_age_remaining_assets !== 0
          ) {
            temp.end_age_remaining_assets_number = 'Please enter a value.';
          }

          if (ans.end_age_remaining_assets !== undefined) {
            if (ans.end_age_remaining_assets < 5000) {
              temp.end_age_remaining_assets_number = `Please enter a value >= ${5000}.`;
            }
          }

          if (ans.end_age_remaining_assets !== undefined) {
            if (
              ans.end_age_remaining_assets >
              Math.max(
                (taxableAssetTotal.p +
                  taxableAssetTotal.s +
                  ntsTotal.p +
                  ntsTotal.s) *
                  0.5,
                5000,
              )
            ) {
              temp.end_age_remaining_assets_number = `Please enter a value <= ${Math.max(
                (taxableAssetTotal.p +
                  taxableAssetTotal.s +
                  ntsTotal.p +
                  ntsTotal.s) *
                  0.5,
                5000,
              )}.`;
            }
          }
        }
        return temp;
      },
    },
    isSubscribed ? RESULT_DESCRIPTION_PAGE_CUSTOM : RESULT_DESCRIPTION_PAGE,
  ];

  const filteredPages: Array<SurveyPageType> = pages.filter(
    d => d.questions(SurveyDefaultAnswers, () => {}).length > 0,
  );

  return filteredPages;
};
